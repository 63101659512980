import { combineReducers } from 'redux';
import dashboardReducer from './Dashboard.reducer';
import eventsReducer from './Events.reducer';
import teamsReducer from './Teams.reducer';
import login from './Authentication/Login.reducer';
import forgotPassword from './Authentication/ForgotPassword.reducer';
import updateProfile from './Authentication/UpdateProfile.reducer';
import league from './League.reducer';
import super8 from './Super8.reducer';
import common from './Common.reducer';


export default combineReducers({
  topDashboardEvents: dashboardReducer,
  event: eventsReducer,
  login: login,
  league: league,
  super8: super8,
  common: common,
  team: teamsReducer,
  forgotPassword: forgotPassword,
  updateProfile:updateProfile 
});