import Cookies from 'universal-cookie';
const cookies = new Cookies();

var Auth_Key = cookies.get('accessToken')
var API_Url = process.env.REACT_APP_END_POINT

const Authorization = (history) => {
  history.push({ hash: '/login' })
  window.location.reload()
}

const checkIsValidToken = (history) => {

  if (cookies.get('accessToken') === null) {
    history.push({ hash: '/login' })
    window.location.reload()
  }
  else {
    Auth_Key = cookies.get('accessToken') !== null ? cookies.get('accessToken') : null
  }
}

const checkLoginValidation = () => {

  if (cookies.get('accessToken')) {
    return true;
  }
  else {
    return false
  }
}

const getUserRole = () => {
  var userProfile = cookies.get('userProfile')
  if (userProfile) {
    if (userProfile.profile) {
      return userProfile.role
    } else {
      return cookies.get('role')
    }

  }
  else
    return false

}


export { Auth_Key, API_Url, Authorization, checkIsValidToken, checkLoginValidation, getUserRole }