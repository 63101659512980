import React, { Component } from 'react';
import Header from "./WebComponents/Header";
import Super8ListRow from "../WebView/WebComponents/super8/Super8ListRow";
import { Row, Col } from 'reactstrap';
import DataTable from "./WebComponents/DataTable";
import LocationBox from "./WebComponents/LocationBox";
import CompanyEventBox from "./WebComponents/CompanyEventBox";
import HotelSlider from "./WebComponents/HotelSlider";
import SponsorBox from "./WebComponents/SponsorBox";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchSuper8, updateSuper8 } from '../saga/actions/Super8.action';
import { fetchLeagueById } from '../saga/actions/League.action';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



class Super8Detail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      event : '',
      eventTeams: ''
    }
    this.getEventType = this.getEventType.bind(this);
    this.getDivision = this.getDivision.bind(this);
    this.fetchSuper8 = this.fetchSuper8.bind(this);
    this.eventCheckedSelect = this.eventCheckedSelect.bind(this);
  }

  componentWillMount() {
    this.props.fetchLeagueById();
    this.fetchSuper8();
  }

  fetchSuper8()
  {
    var super8tId = localStorage.getItem('super8Id');
    this.props.fetchSuper8(super8tId);
  }

  componentWillReceiveProps(nextProps) {

    if(nextProps.leagueData.league && nextProps.leagueData.isLeagueLoaded)
    {
      nextProps.leagueData.isLeagueLoaded = false
      var leagueData= nextProps.leagueData.league;  

      this.setState({
        league: leagueData
      })
    }

    if(nextProps.super8Data.super8 && nextProps.super8Data.isSuper8Loaded)
    {
      nextProps.super8Data.isSuper8Loaded = false
      var super8= nextProps.super8Data.super8;
      this.setState({
        event: super8
      })
    }

    if(nextProps.super8Data.isUpdated)
    {
      nextProps.super8Data.isUpdated = false     
      toast.success("Event updated Successfully.", {
        position: toast.POSITION.TOP_RIGHT
      });
      this.fetchSuper8();
    }

    if(nextProps.super8Data.isUpdatedError)
    {
      nextProps.super8Data.isUpdatedError = ''
      toast.error("You can't select more then 8 events.", {
        position: toast.POSITION.TOP_RIGHT
      });
    }
  }

  getEventType(eventType) {
    if(eventType === 0)
      return 'Team'
    else if(eventType === 1)
      return 'Showcase'
    else
      return 'Other'
  }

  getDivision(division) {
    if(division){
      return division.map((div)=>{
        return div.title;
     }).join(",")
    }
     else{
       return ""
     }
  }

  eventCheckedSelect = (super8) => {
    var event = {
      leageId: super8.league,
      eventId: super8._id,
      flag: super8.selPer === 0 ? true : false
    }
    this.props.updateSuper8(event)

  }

  render() {

    const  event  = this.state.event;

      return (
      <div className="web_wrapper">
        <div className="web_main inner_pages">
          <div className="web_banner">
            <img src="/web_images/banner.png" alt="banner" />
          </div>
          <div className="web_container">
          <Header history={this.props.history}/>
          </div>
        </div>
        <div className="web_container_1290">
          <div className="web_eventOuterList">
            {
              event && <Super8ListRow leagueData={this.state.league} eventSelect={this.eventCheckedSelect} history={this.props.history} isHideJoinButton={false} eventData={event} />
            }
          
          </div>
          <Row>
            <Col lg={8}>
              <div className="web_description_box">
                <div className="web_desc_title">
                  <h3>Details</h3>
                </div>
                <div className="web_list_data d-flex flex-wrap">
                  <div className="web_list_data_box text-center">
                    <label>Event Type</label>
                      <h3>Super 8</h3>
                  </div>
                  <div className="web_list_data_box text-center">
                    <label>Division</label>
                    <h3>{this.getDivision(event.div)}</h3>
                  </div>
                  <div className="web_list_data_box text-center">
                    <label>Age Group</label>
                      <h3>{ event.ageGroup }</h3>
                  </div>
                  <div className="web_list_data_box text-center">
                    <label>Location</label>
                      <h3>{ event.location && event.location.city }</h3>
                  </div>
                  {/* <div className="web_list_data_box text-center">
                    <label>Spots Available</label>
                      <h3>{ event.setting && event.settings.spotAvailable === true ? 'Yes' : 'No' }</h3>
                  </div> */}
                </div>
                <div className="web_desc_title">
                  <h3>Description</h3>
                </div>
                  <p>{ event.information && event.information.event }</p>
              </div>
              <div className="web_description_box">
                <div className="web_desc_title">
                  <h3>Tournament Format</h3>
                </div>
                <p>{ event.information && event.information.tournamentFormat }</p>
              </div>
              <div className="web_description_box">
                <div className="web_desc_title">
                  <h3>Tournament Rules</h3>
                </div>
                <p>{ event.information && event.information.tournamentRule }</p>
              </div>
              <div className="web_description_box">
                <div className="web_desc_title">
                  <h3>Gate Information</h3>
                </div>
                <p>{ event.information && event.information.gateInfo }</p>
              </div>

              {

                this.state.eventTeams && this.state.eventTeams.length > 0 ?
                <div className="web_table">
                  <div className="web_table_heading">
                      <h3>Participating Teams</h3>
                  </div>
                  <DataTable teamData={ this.state.eventTeams }/>
                </div> : ''

              }

            </Col>
            <Col lg={4}>
              <LocationBox location={event.location}/>
              <CompanyEventBox company={event.companies}/>
              {event.sponsor && <SponsorBox sponsor={event.sponsor}/>}

              {
                event.hotels && event.hotels.length > 0 ? <HotelSlider hotels={event.hotels}/> : ''
              }
              
            </Col>
          </Row>
        </div>
        <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={2000} />
      </div>
    );
  }
}

Super8Detail.propTypes = {
  fetchSuper8: PropTypes.func.isRequired,
  updateSuper8: PropTypes.func.isRequired,
  fetchLeagueById: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  super8Data: state.super8,
  leagueData: state.league
});

export default connect(mapStateToProps, { fetchSuper8, updateSuper8, fetchLeagueById })(Super8Detail);