import {

  FETCH_ALL_LEAGUES_REQUEST,
  FETCH_ALL_LEAGUES_SUCCESS,
  FETCH_ALL_LEAGUES_ERROR,

  JOIN_REQUEST,
  JOIN_SUCCESS,
  JOIN_ERROR,

  FETCH_LEAGUE_BY_ID_REQUEST,
  FETCH_LEAGUE_BY_ID_SUCCESS,
  FETCH_LEAGUE_BY_ID_ERROR,

  FETCH_PAYMENT_INTENT_REQUEST,
  FETCH_PAYMENT_INTENT_SUCCESS,
  FETCH_PAYMENT_INTENT_ERROR,

  FETCH_VENMO_TOKEN_REQUEST,
  FETCH_VENMO_TOKEN_SUCCESS,
  FETCH_VENMO_TOKEN_ERROR,

  SEND_NONCE_REQUEST,
  SEND_NONCE_SUCCESS,
  SEND_NONCE_ERROR,

  PAYMENT_COMPLETE_REQUEST,
  PAYMENT_COMPLETE_SUCCESS,
  PAYMENT_COMPLETE_ERROR,

  EVENT_REGISTRATION_REQUEST,
  EVENT_REGISTRATION_SUCCESS,
  EVENT_REGISTRATION_ERROR


} from '../constants/League.const';

const initialState = {
  allLeagues: [],
  allLeaguesError: null,
  allLeaguesLoader: false,
  isAllLeaguesLoaded: false,

  paymentIntent: '',
  paymentIntentLoader: false,
  paymentIntentError: null,

  venmoToken: '',
  venmoTokenLoader: false,
  venmoTokenError: null,

  isSended: false,
  paymentObject: [],
  isSendedLoader: false,
  isSendedError: null,

  joinedLeague: "",
  isJoined: false,
  isJoinedError: null,
  isJoinedLoader: false,

  paymentComplete: "",
  isComplete: false,
  isCompleteError: null,
  isCompleteLoader: false
};

export default function (state = initialState, action) {
  switch (action.type) {

    // For Send Nance Token to Server

    case SEND_NONCE_REQUEST:
      return {
        ...state,
        isSendedLoader: true
      };
    case SEND_NONCE_SUCCESS:
      return {
        ...state,
        paymentObject: action.payload,
        isSended: true,
        isSendedLoader: false
      };
    case SEND_NONCE_ERROR:
      return {
        ...state,
        isSendedError: action.error.message,
        isSendedLoader: false
      };

    // For GET Payment Intent

    case FETCH_VENMO_TOKEN_REQUEST:
      return {
        ...state,
        venmoTokenLoader: true
      };
    case FETCH_VENMO_TOKEN_SUCCESS:
      return {
        ...state,
        venmoToken: action.payload,
        venmoTokenLoader: false
      };
    case FETCH_VENMO_TOKEN_ERROR:
      return {
        ...state,
        venmoTokenError: action.error.message,
        venmoTokenLoader: false
      };

    // For GET Payment Intent

    case FETCH_PAYMENT_INTENT_REQUEST:
      return {
        ...state,
        paymentIntentLoader: true
      };
    case FETCH_PAYMENT_INTENT_SUCCESS:
      return {
        ...state,
        paymentIntent: action.payload,
        paymentIntentLoader: false
      };
    case FETCH_PAYMENT_INTENT_ERROR:
      return {
        ...state,
        paymentIntentError: action.error.message,
        paymentIntentLoader: false
      };

    // For JOIN League

    case JOIN_REQUEST:
      return {
        ...state,
        isJoinedLoader: true
      };
    case JOIN_SUCCESS:
      return {
        ...state,
        isJoined: true,
        joinedLeague: action.payload,
        isJoinedLoader: false
      };
    case JOIN_ERROR:
      return {
        ...state,
        isJoinedError: action.error.message,
        isJoinedLoader: false
      };
    // evant join after patment
    case PAYMENT_COMPLETE_REQUEST:
      return {
        ...state,
        isCompleteLoader: true
      };
    case PAYMENT_COMPLETE_SUCCESS:
      console.log("payment complete success:-----", action.payload);
      return {
        ...state,
        isComplete: true,
        paymentComplete: action.payload,
        isCompleteLoader: false
      };
    case PAYMENT_COMPLETE_ERROR:
      return {
        ...state,
        isCompleteError: action.error.message,
        isCompleteLoader: false
      };
    // league confirm after payment
    case EVENT_REGISTRATION_REQUEST:
      return {
        ...state,
        isCompleteLoader: true
      };
    case EVENT_REGISTRATION_SUCCESS:
      return {
        ...state,
        isComplete: true,
        paymentComplete: action.payload,
        isCompleteLoader: false
      };
    case EVENT_REGISTRATION_ERROR:
      return {
        ...state,
        isCompleteError: action.error.message,
        isCompleteLoader: false
      };

    // For GET League Data By Id

    case FETCH_LEAGUE_BY_ID_REQUEST:
      return {
        ...state,
        leagueLoader: true
      };
    case FETCH_LEAGUE_BY_ID_SUCCESS:
      return {
        ...state,
        isLeagueLoaded: true,
        league: action.payload,
        leagueLoader: false
      };
    case FETCH_LEAGUE_BY_ID_ERROR:
      return {
        ...state,
        leagueError: action.error.message,
        leagueLoader: false
      };

    // For GET ALL League Data

    case FETCH_ALL_LEAGUES_REQUEST:
      return {
        ...state,
        allLeaguesLoader: true
      };
    case FETCH_ALL_LEAGUES_SUCCESS:
      return {
        ...state,
        isAllLeaguesLoaded: true,
        allLeagues: action.payload,
        allLeaguesLoader: false
      };
    case FETCH_ALL_LEAGUES_ERROR:
      return {
        ...state,
        allLeaguesError: action.error.message,
        allLeaguesLoader: false
      };

    default:
      return state;
  }
}