import React from 'react';
import moment from 'moment'
import Login from "./Login"
import {checkLoginValidation, getUserRole} from '../../saga/helpers/authToken';

class EventListRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userRole: getUserRole()
    }
    this.payment = this.payment.bind(this);
    this.isShowJoin = this.isShowJoin.bind(this);
  }

  payment = () => {

    var isloggedIn = checkLoginValidation();

    if(isloggedIn)
    {
      this.props.history.push("/WebApp/join")
    }
    else
    {
      this.setState({
        isShowLogin: true
      })
    }
  }

  popupClose = () => {
    this.setState({
      isShowLogin: false
    })
  }

  isShowJoin = () => {
    const event = this.props.eventData;

    if(event.eventTeamType === 1)
    {
      if(this.state.userRole === 'Player' || this.state.userRole === 'Fan')
      {
        return null;
      }
      else
      {
        if(event.selPer === 4)
        {
          return (this.props.isHideJoinButton ? '' : <button className="btn_green"  style={{cursor: 'not-allowed'}} >Joined</button>)
        }
        else
        {
          return (this.props.isHideJoinButton ? '' : <button className="btn_green" onClick={this.payment} >Join</button>)
        }
        
      }
    }

    if(event.eventTeamType === 2)
    {
      if(this.state.userRole === 'Coach' || this.state.userRole === 'Fan')
      {
        return null;
      }
      else
      {
        if(event.selPer === 4)
        {
          return (this.props.isHideJoinButton ? '' : <button className="btn_green"  style={{cursor: 'not-allowed'}} >Joined</button>)
        }
        else
        {
          return (this.props.isHideJoinButton ? '' : <button className="btn_green" onClick={this.payment} >Join</button>)
        }

      }
    }
  
  }

  render() {
    const event = this.props.eventData;
    return (
      <div className="web_eventlist_wrapper d-flex flex-wrap align-items-center">
        <div className="web_eventList_img event_card_img">
          <img src={event.eventImages && event.eventImages.filePath ? event.eventImages && event.eventImages.filePath : "/web_images/Default_Banner.png"} alt="event banner" />
          <span className="event_card_address">{ event.location && event.location.city }</span>
        </div>
        <div className="web_eventList_content d-flex flex-wrap align-items-center">
          <div className="web_eventList_data d-flex flex-wrap align-items-center">
            <div className="web_eventList_logo">
              <img src={ event.eventLogo && event.eventLogo.filePath ? event.eventLogo && event.eventLogo.filePath :"/web_images/Default_Banner.png"} alt="event logo" />
            </div>
            <div className="web_eventList_detail">
              <h1>{ event.eventTitle }</h1>
              <div className="web_eventList_icons d-flex flex-wrap">
                <span className="common_softball_icon d-flex align-items-center">{ event.eventType === 1 ? 'Baseball' : 'Softball' }</span>
                <span className="common_date_icon d-flex align-items-center">{moment(event.startDate).format('DD-MMM')} - {moment(event.endDate).format('DD-MMM')}</span>
                <span className="common_team_icon d-flex align-items-center">{event.teamLimit} {event.eventTeamType === 1 ? 'Teams' : 'Players'}</span>
              </div>
            </div>
          </div>
          {
            this.props.isJoined === true ? 

            <div>
              <button className="btn_green">Joined</button>
            </div> :

          <div className="web_eventList_join">
            <h2>${event.payment && event.payment.feesTournament} <span>Fee to Join</span></h2>
            {
              this.isShowJoin()              
            }
          </div>
          }
        </div>
        <div>
          {
            this.state.isShowLogin ? <Login history={this.props.history} class='custom_popup open auth_form m-0' onClose={this.popupClose} /> : ''
          }
        </div>
      </div>
    );
  }
}

export default EventListRow;