import React from 'react';
import { MDBDataTable } from 'mdbreact';

class DataTable extends React.Component {

  render() {
    return (
      <MDBDataTable
      borderless
      theadColor="#ff0000"
      searching={this.props.isShowSearch === true ? true : false}
      displayEntries={false}
      data={this.props.data}
      sortable={false}
      info={false}
      hover 
      paging={false}
      noBottomColumns
      responsive
    />
    );
  }
}

export default DataTable;