import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Header from "./WebComponents/Header";
import EventListRow from "./WebComponents/EventListRow";
import HotelNearVenue from "./WebComponents/HotelNearVenue";
import { fetchEventById } from "../saga/actions/Events.action";
import { Row } from "reactstrap";

class WebEventJoined extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      transactioId: "2432655675e464324",
      amount: 0,
      paymentMethod: 1,
      card: "**** **** **** 7567",
    };
  }

  componentWillMount() {
    var eventId = localStorage.getItem("eventId");
    this.props.fetchEventById(eventId);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.eventData) {
      if (nextProps.eventData.isEventLoaded) {
        nextProps.eventData.isEventLoaded = false;
        const Event = nextProps.eventData.event;
        this.setState({
          event: Event,
        });
        nextProps.eventData.Event = [];
      }
    }

    if (nextProps.joinedLeague.paymentComplete.paymentType === 2) {
      if (
        nextProps.joinedLeague &&
        nextProps.joinedLeague.paymentComplete
      ) {

        this.setState({
          transactioId: nextProps.joinedLeague.paymentComplete._id,
          amount: nextProps.joinedLeague.joinedLeague.amount,
          paymentMethod: nextProps.joinedLeague.joinedLeague.paymentType,
        });

      }
    } else {
      if (
        nextProps.joinedLeague &&
        nextProps.joinedLeague.joinedLeague.metaPayment &&
        nextProps.joinedLeague.paymentComplete
      ) {

        this.setState({
          transactioId: nextProps.joinedLeague.paymentComplete._id,
          amount: nextProps.joinedLeague.joinedLeague.amount,
          paymentMethod: nextProps.joinedLeague.joinedLeague.paymentType,
          card:
            "**** **** **** " +
            nextProps.joinedLeague.joinedLeague.metaPayment.payment_method_details
              .card.last4,
        });

        nextProps.joinedLeague.joinedLeague = "";
      }
    }




  }

  render() {
    const event = this.state.event;
    var paymentMath = "Full Payment $" + this.state.amount;

    if (this.state.paymentMethod === 1) {
      paymentMath = "Full Payment $" + this.state.amount;
    } else if (this.state.paymentMethod === 2) {
      paymentMath = "Day Off $" + this.state.amount;
    } else if (this.state.paymentMethod === 3) {
      paymentMath = "Pay First Installment $" + this.state.amount;
    }
    return (
      <div className="web_wrapper">
        <div className="web_main inner_pages">
          <div className="web_banner">
            <img src="/web_images/banner.png" alt="banner" />
          </div>
          <div className="web_container">
            <Header history={this.props.history} />
          </div>
        </div>
        <div className="web_container_1290">
          <div className="web_eventOuterList">
            {event && (
              <EventListRow
                history={this.props.history}
                isHideJoinButton={true}
                eventData={event}
                isJoined={true}
              />
            )}
          </div>
        </div>
        <div className="container_900">
          <div className="bg_white web_description_box">
            <div className="web_join_title text-center">
              <h1 className="green_text">Thank You!</h1>
              <p> {this.state.paymentMethod === 2 ? "Thanks for registering. Your payment is due the day of the event." : "Thanks for registering."}</p>
            </div>
            <div className="transaction_detail">
              <div className="transaction_row d-flex flex-wrap align-items-center">
                <label>Transaction ID:</label>
                <p>{this.state.transactioId}</p>
              </div>
              <div className="transaction_row d-flex flex-wrap align-items-center">
                <label>Payment Method:</label>
                <p>{paymentMath}</p>
              </div>
              {
                this.state.paymentMethod !== 2 &&
                <>
                  <div className="transaction_row d-flex flex-wrap align-items-center">
                    <label>Payment Type:</label>
                    <p>Credit Card</p>
                  </div>
                  <div className="transaction_row d-flex flex-wrap align-items-center">
                    <label>Card Number:</label>
                    <p>{this.state.card}</p>
                  </div>
                </>
              }
            </div>

            {event && event.hotels && event.hotels.length > 0 ? (
              <div className="web_hotel_venue_main">
                <div className="text-center">
                  <h1>Hotels Near Venue</h1>
                </div>
                <Row>
                  {event.hotels.map((hotel, index) => (
                    <HotelNearVenue key={index} hotelData={hotel} />
                  ))}
                </Row>
              </div>
            ) : (
                ""
              )}
          </div>
        </div>
      </div>
    );
  }
}

WebEventJoined.propTypes = {
  fetchEventById: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  eventData: state.event,
  joinedLeague: state.league,
  paymentComplete: state.league,
});

export default connect(mapStateToProps, { fetchEventById })(WebEventJoined);
