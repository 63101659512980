import { 

  FETCH_SUPER8_BY_ID_REQUEST,
  FETCH_SUPER8_BY_ID_SUCCESS,
  FETCH_SUPER8_BY_ID_ERROR,

  UPDATE_SUPER8_REQUEST,
  UPDATE_SUPER8_SUCCESS,
  UPDATE_SUPER8_ERROR,  

  FETCH_SUPER8_REQUEST,
  FETCH_SUPER8_SUCCESS,
  FETCH_SUPER8_ERROR,

} from '../constants/Super8.const';

const initialState = {
  allSuper8: [],
  allSuper8Error: null,
  allSuper8Loader: false,
  isAllSuper8Loaded : false,

  super8: [],
  super8Error: null,
  super8Loader: false,
  isSuper8Loaded : false,

  isUpdated: false,
  isUpdatedError: null,
  isUpdatedLoader: false
};

export default function(state = initialState, action) {
  switch (action.type) {


    // For GET Super 8 

    case FETCH_SUPER8_REQUEST:
      return {
        ...state,
        super8Loader: true
      };
    case FETCH_SUPER8_SUCCESS:
      return {
        ...state,
        isSuper8Loaded : true,
        super8: action.payload,
        super8Loader: false
      };
    case FETCH_SUPER8_ERROR:
      return {
        ...state,
        super8Error: action.error.message,
        super8Loader: false
      };

    // For GET League Data By Id

    case FETCH_SUPER8_BY_ID_REQUEST:
      return {
        ...state,
        allSuper8Loader: true
      };
    case FETCH_SUPER8_BY_ID_SUCCESS:
      return {
        ...state,
        isAllSuper8Loaded : true,
        allSuper8: action.payload,
        allSuper8Loader: false
      };
    case FETCH_SUPER8_BY_ID_ERROR:
      return {
        ...state,
        allSuper8Error: action.error.message,
        allSuper8Loader: false
      };

    // For UPDATE Super 8

    case UPDATE_SUPER8_REQUEST:
      return {
        ...state,
        isUpdatedLoader: true
      };
    case UPDATE_SUPER8_SUCCESS:
      return {
        ...state,
        isUpdated: true,
        isUpdatedLoader: false
      };
    case UPDATE_SUPER8_ERROR:
      return {
        ...state,
        isUpdatedError: action.error,
        isUpdatedLoader: false
      };

    default:
      return state;
  }
}