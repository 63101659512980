import React, { Component } from "react";
import Header from "./WebComponents/Header";
import EventListRow from "./WebComponents/EventListRow";
import { Row, Col } from "reactstrap";
import DataTable from "./WebComponents/DataTable";
import LocationBox from "./WebComponents/LocationBox";
import SponsorBox from "./WebComponents/SponsorBox";
import CompanyEventBox from "./WebComponents/CompanyEventBox";
import HotelSlider from "./WebComponents/HotelSlider";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { fetchEventById } from "../saga/actions/Events.action";
import Login from "./WebComponents/Login";
import { checkLoginValidation } from "../saga/helpers/authToken";

class WebEventDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      event: "",
      eventTeams: "",
      isShowLogin: false,
    };
    this.getEventType = this.getEventType.bind(this);
    this.getDivision = this.getDivision.bind(this);
  }

  componentWillMount() {
    var eventId = localStorage.getItem("eventId");
    var isloggedIn = checkLoginValidation();
    if (isloggedIn) {
      this.props.fetchEventById(eventId);
    } else {
      this.setState({ isShowLogin: true });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.eventData) {
      if (nextProps.eventData.isEventLoaded) {
        nextProps.eventData.isEventLoaded = false;

        const Event = nextProps.eventData.event;

        console.log("Event:--", Event);

        this.setState({
          event: Event,
        });
        nextProps.eventData.Event = [];
      }
    }

    if (nextProps.teamData) {
      if (nextProps.teamData.isTeamsLoaded) {
        nextProps.teamData.isTeamsLoaded = false;
        const Teams = nextProps.teamData.allTeams;
        this.setState({
          eventTeams: Teams,
        });
        nextProps.teamData.allTeams = [];
      }
    }
  }

  getEventType(eventTeamType) {
    if (eventTeamType === 1) return "Team";
    else return "Showcase";
  }
  popupClose = () => {
    this.setState({
      isShowLogin: false,
    });
  };

  getDivision(division) {
    if (division) {
      return division
        .map((div) => {
          return div.title;
        })
        .join(",");
    } else {
      return "";
    }
  }

  render() {
    const event = this.state.event;
    console.log("event:------------", event);
    return (
      <div className="web_wrapper">
        <div className="web_main inner_pages">
          <div className="web_banner">
            <img src="/web_images/banner.png" alt="banner" />
          </div>
          <div className="web_container">
            <Header history={this.props.history} />
          </div>
        </div>
        <div className="web_container_1290">
          <div className="web_eventOuterList">
            <EventListRow
              history={this.props.history}
              isHideJoinButton={false}
              eventData={event}
            />
          </div>
          <Row>
            <Col lg={8}>
              <div className="web_description_box">
                <div className="web_desc_title">
                  <h3>Details</h3>
                </div>
                <div className="web_list_data d-flex flex-wrap">
                  <div className="web_list_data_box text-center">
                    <label>Event Type</label>
                    <h3>{this.getEventType(event.eventTeamType)}</h3>
                  </div>
                  <div className="web_list_data_box text-center">
                    <label>Division</label>
                    <h3>{this.getDivision(event.div)}</h3>
                  </div>
                  <div className="web_list_data_box text-center">
                    <label>Age Group</label>
                    <h3>{event.ageGroup + " - " + event.ageGroupTo}</h3>
                  </div>
                  <div className="web_list_data_box text-center">
                    <label>Location</label>
                    <h3>{event.location && event.location.city}</h3>
                  </div>
                  {event.settings && event.settings.spotAvailable === true && (
                    <div className="web_list_data_box text-center">
                      <label>Spots Available</label>
                      <h3>{event.sportAvailable}</h3>
                    </div>
                  )}
                </div>
                <div className="web_desc_title">
                  <h3>Description</h3>
                </div>
                <p>{event.information && event.information.event}</p>
              </div>
              <div className="web_description_box">
                <div className="web_desc_title">
                  <h3>
                    {event.eventTeamType === 2 ? "Showcase" : "Tournament"}{" "}
                    Format
                  </h3>
                </div>
                <p>{event.information && event.information.tournamentFormat}</p>
              </div>
              <div className="web_description_box">
                <div className="web_desc_title">
                  <h3>
                    {event.eventTeamType === 2 ? "Showcase" : "Tournament"}{" "}
                    Rules
                  </h3>
                </div>
                <p>{event.information && event.information.tournamentRule}</p>
              </div>
              <div className="web_description_box">
                <div className="web_desc_title">
                  <h3>Gate Information</h3>
                </div>
                <p>{event.information && event.information.gateInfo}</p>
              </div>

              {this.state.eventTeams && this.state.eventTeams.length > 0 ? (
                <div className="web_table">
                  <div className="web_table_heading">
                    <h3>Participating Teams</h3>
                  </div>
                  <DataTable teamData={this.state.eventTeams} />
                </div>
              ) : (
                ""
              )}
            </Col>
            <Col lg={4}>
              <LocationBox location={event.location} />
              <CompanyEventBox company={event.companies} />
              {event.sponsor && <SponsorBox sponsor={event.sponsor} />}

              {event.hotels && event.hotels.length > 0 ? (
                <HotelSlider hotels={event.hotels} />
              ) : (
                ""
              )}
            </Col>
          </Row>
        </div>
        {this.state.isShowLogin ? (
          <Login
            history={this.props.history}
            class="custom_popup open auth_form m-0"
            onClose={this.popupClose}
          />
        ) : (
          ""
        )}
      </div>
    );
  }
}

// WebEventDetail.propTypes = {};
WebEventDetail.propTypes = {
  fetchEventById: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  eventData: state.event,
  teamData: state.team,
});

export default connect(mapStateToProps, { fetchEventById })(WebEventDetail);
