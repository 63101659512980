import axios from 'axios';
import { Auth_Key, API_Url } from '../helpers/authToken'
import {
    UPLOAD_WAIVERS_REQUEST,
    UPLOAD_WAIVERS_SUCCESS,
    UPLOAD_WAIVERS_ERROR,

    UPLOAD_INSURANCE_REQUEST,
    UPLOAD_INSURANCE_SUCCESS,
    UPLOAD_INSURANCE_ERROR,

    IMAGE_DELETE_REQUEST,
    IMAGE_DELETE_SUCCESS,
    IMAGE_DELETE_ERROR,

    GET_FAQS_REQUEST,
    GET_FAQS_SUCCESS,
    GET_FAQS_ERROR,

    GET_TC_REQUEST,
    GET_TC_SUCCESS,
    GET_TC_ERROR,

    GET_PL_REQUEST,
    GET_PL_SUCCESS,
    GET_PL_ERROR,
} from '../constants/Common.const';

export const uploadWaivers = (imageData) => dispatch => {
    dispatch({ type: UPLOAD_WAIVERS_REQUEST })
    let formData = new FormData();
    formData.append('file', imageData.data);
    axios({
        method: 'post',
        url: `${API_Url}file/upload?documentType=${imageData.documentType}`,
        data: formData,
        headers: {
            'x-auth-token': Auth_Key,
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data'
        }
    })

        .then(image => {
            dispatch({
                type: UPLOAD_WAIVERS_SUCCESS,
                payload: image.data.result
            })
        })
        .catch((error) => {
            dispatch({ type: UPLOAD_WAIVERS_ERROR, error: error })
        });
};

export const uploadInsurance = (imageData) => dispatch => {
    dispatch({ type: UPLOAD_INSURANCE_REQUEST })
    let formData = new FormData();
    formData.append('file', imageData.data);
    axios({
        method: 'post',
        url: `${API_Url}file/upload?documentType=${imageData.documentType}`,
        data: formData,
        headers: {
            'x-auth-token': Auth_Key,
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data'
        }
    })

        .then(image => {
            dispatch({
                type: UPLOAD_INSURANCE_SUCCESS,
                payload: image.data.result
            })
        })
        .catch((error) => {
            dispatch({ type: UPLOAD_INSURANCE_ERROR, error: error })
        });
};

export const deleteImage = (imageId) => dispatch => {

    dispatch({ type: IMAGE_DELETE_REQUEST })
    axios({
        method: 'DELETE',
        url: `${API_Url}file/${imageId}`,
        headers: {
            'x-auth-token': Auth_Key,
            'Accept': 'application/json'
        }
    })

        .then(images => {
            dispatch({
                type: IMAGE_DELETE_SUCCESS,
                payload: true
            })
        })
        .catch((error) => {
            if (error.response) {
                dispatch({ type: IMAGE_DELETE_ERROR, error: error.response.data.message })
            }

        });
};

export const GetFaqs = () => dispatch => {
    dispatch({ type: GET_FAQS_REQUEST })

    axios({
        method: 'get',
        url: `${API_Url}setting/faq`,
        headers: {
            'Accept': 'application/json'
        }
    })

        .then(faq => {
            dispatch({
                type: GET_FAQS_SUCCESS,
                payload: faq.data.result
            })
        })
        .catch((error) => {
            dispatch({ type: GET_FAQS_ERROR, error: error })
        });
};

export const GetTandC = () => dispatch => {
    dispatch({ type: GET_TC_REQUEST })

    axios({
        method: 'get',
        url: `${API_Url}setting/termcondition`,
        headers: {
            'Accept': 'application/json'
        }
    })

        .then(tc => {
            dispatch({
                type: GET_TC_SUCCESS,
                payload: tc.data.result
            })
        })
        .catch((error) => {
            dispatch({ type: GET_TC_ERROR, error: error })
        });
};

export const GetPolicy = () => dispatch => {
    dispatch({ type: GET_PL_REQUEST })

    axios({
        method: 'get',
        url: `${API_Url}setting/privacypolicy`,
        headers: {
            'Accept': 'application/json'
        }
    }).then(pl => {
        dispatch({
            type: GET_PL_SUCCESS,
            payload: pl.data.result
        })
    }).catch((error) => {
        dispatch({ type: GET_PL_ERROR, error: error })
    });
};

