import React from "react";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchPaymentIntent } from '../../../saga/actions/League.action';
import { Row, Col, Label, FormGroup, Input } from 'reactstrap';
import StripePayment from '../Payment/Stripe';
import VenmoPayment from '../Payment/Venmo';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SimpleReactValidator from 'simple-react-validator';

class LeaguePayment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      paymentForm: {
        couponCode: '',
        paymentType: '', 
        paymentMethod: '',
        payments: []
      },
      payment: 0,
      paymentMethod: '',
      Creditpopup:false,
      Venmopopup:false
    };
    this.validator = new SimpleReactValidator({autoForceUpdate: this});
  }

  componentDidMount() {
    const league = this.props.leagueData;

     if(league.payment.paymentType.length === 1 && league.payment.paymentType.indexOf(2) !== -1)
     {
      this.setState({
        paymentForm: {
          ...this.state.paymentForm,
          paymentType: 2
        }
      })
     }
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.leaguePayment)
    {
      if(nextProps.leaguePayment.paymentIntent)
      {
        localStorage.setItem('stripeIntent', nextProps.leaguePayment.paymentIntent)
        nextProps.leaguePayment.paymentIntent= ''
      }

      if(nextProps.leaguePayment.paymentIntentError)
      {
        toast.error(nextProps.leaguePayment.paymentIntentError, {
          position: toast.POSITION.TOP_RIGHT
        });
        // this.setState({
        //   paymentForm: {
        //     ...this.state.paymentForm,
        //     paymentType: ''
        //   }
        // })
        nextProps.leaguePayment.paymentIntentError = ''
      }
    }
  }

  handlePaymentTypes = (e) => {
    e.preventDefault();

    var amount = 0;
    var paymentType = JSON.parse(e.target.value);

    if(paymentType === 1 || paymentType === 2)
      amount = this.props.leagueData.payment.feesLeague
    else if(paymentType === 3)
      amount = this.props.leagueData.payment.installments[0].price

    this.setState({
      payment : amount,
      paymentForm: {
        ...this.state.paymentForm,
        paymentType: paymentType
      }
    })

    this.props.handlePaymentPage('paymentType', paymentType)
    this.props.handlePaymentPage('amount', amount)

    var payment= {
      amount: amount,
      objetId: this.props.leagueData._id,
      module: 2,
      method: this.state.paymentMethod === 'creditcard' ? 'stripe' : 'venmo'
    }
    this.props.fetchPaymentIntent(payment)
  }

  handlePaymentMethods = (e) => {
    e.preventDefault();


    if (this.validator.allValid())
    {
      var name = e.target.name;
      this.setState({
        paymentMethod: name,
        paymentForm: {
          ...this.state.paymentForm,
          paymentMethod: name === 'creditcard' ? 1 : 2
        }
      })

      this.props.handlePaymentPage('paymentMethod', name === 'creditcard' ? 1 : 2)

      if(name === 'creditcard')
        this.setState({ Creditpopup:true })
      else
        this.setState({ Venmopopup:true })
    }
    else
    {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }

  closeStripePopup = () => {
    this.setState({
      Creditpopup:false
    })
  }

  closeVenmoPopup = () => {
    this.setState({
      Venmopopup: false
    })
  }

  stripePaymentSuccess = (payment) => {
    this.setState({
      Creditpopup: false
    })
    toast.success('Paymet Sucsess.', {
      position: toast.POSITION.TOP_RIGHT
    });
    this.props.showPaymentButton(payment)
   // this.props.stripePaymentSuccessful(payment)
  }

  stripePaymentError = (error) => {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT
    });
  }

  sendNanceTokenToServer = (nanceToken) => {
    this.setState({
      Venmopopup: false
    })
    this.props.sendNanceTokenToServer(nanceToken)
  }

  onHandleChange = (e) => {
    e.preventDefault();

    const {name, value} = e.target;
    this.setState({
      paymentForm: {
        ...this.state.paymentForm,
        [name]: value
      }
    })
    this.props.handlePaymentPage('couponCode', value)
  }

  render() {
     const league = this.props.leagueData;
     var isPaymentDisabled = false;
     if(league.payment.paymentType.length === 1 && league.payment.paymentType.indexOf(2) !== -1)
     {
      isPaymentDisabled= true     
     }
     else
     {
      isPaymentDisabled= false
     }

     const { paymentForm } = this.state;
    return (
        <div className="bg_white web_description_box">
        <div className="web_join_title text-center">
          <h2>Payment</h2>
        </div>
        <Row>
          <Col lg={6} className="web_event_join">
            <div className="create_event_row">
            <FormGroup>
                <Label>Apply Coupon Code</Label>
                <input type="text" name="couponCode" placeholder="#EDF458" onChange={this.onHandleChange} />
              </FormGroup>
            </div>
            <div className="create_event_row mb-4">
              {
                isPaymentDisabled ? 

                <FormGroup>
                <Label>Payment Type</Label>
                <div className="select_custom">
                  <select disabled onChange={this.handlePaymentTypes}>
                    <option selected disabled value={2}>Day Of ( ${league.payment.feesLeague} )</option>
                  </select>
                  <span className="angle_down"><i className="fa fa-angle-down" aria-hidden="true"></i></span>
                </div>
              </FormGroup>

              :

              <FormGroup>
                <Label>Payment Type</Label>
                <div className="select_custom">
                  <select onChange={this.handlePaymentTypes}>
                    <option disabled selected>Please select paymentType</option>
                    {league && league.payment && league.payment.paymentType.indexOf(1) !== -1 ? <option value={1}>Full Payment ( ${league.payment.feesLeague} )</option> : null}
                    {league && league.payment && league.payment.paymentType.indexOf(2) !== -1 ? <option value={2}>Day Of ( ${league.payment.feesLeague} )</option> : null}
                    {league && league.payment && league.payment.paymentType.indexOf(3) !== -1 && league.payment.installments.length > 0 ? <option value={3}>Pay First Installment ( ${league.payment.installments[0].price} )</option> : null}
                  </select>
                  <span className="angle_down"><i className="fa fa-angle-down" aria-hidden="true"></i></span>
                </div>
                <span style={{color : 'red'}}>{this.validator.message('PaymentType',paymentForm.paymentType, 'required')}</span>
                <span style={{color : 'red'}}>{this.props.paymentTypeError}</span>  
              </FormGroup>

              }
              {/* <FormGroup>
                <Label>Payment Type</Label>
                <div className="select_custom">
                  <select onChange={this.handlePaymentTypes}>
                    <option disabled selected>Please select paymentType</option>
                    {league && league.payment && league.payment.paymentType.indexOf(1) !== -1 ? <option value={1}>Full Payment ( ${league.payment.feesLeague} )</option> : null}
                    {league && league.payment && league.payment.paymentType.indexOf(2) !== -1 ? <option value={2}>Day Of ( ${league.payment.feesLeague} )</option> : null}
                    {league && league.payment && league.payment.paymentType.indexOf(3) !== -1 && league.payment.installments.length > 0 ? <option value={3}>Pay First Installment ( ${league.payment.installments[0].price} )</option> : null}
                  </select>
                  <span className="angle_down"><i className="fa fa-angle-down" aria-hidden="true"></i></span>
                </div>
                <span style={{color : 'red'}}>{this.validator.message('PaymentType',paymentForm.paymentType, 'required')}</span>
                <span style={{color : 'red'}}>{this.props.paymentTypeError}</span>  
              </FormGroup> */}
            </div>
          </Col>
          <Col lg={12}>
            <div className="create_event_row">
              <label>Payment Method</label>
              <Row>
                {
                  league && league.payment && league.payment.paymentMethod.indexOf(1) !== -1 ? 
                  <Col sm={3} xs={6}>
                  <div className="chk_box">
                    <Input
                      disabled={isPaymentDisabled || this.state.paymentForm.paymentType === 2}  
                      style={{cursor: isPaymentDisabled ? 'not-allowed' : 'pointer'}}
                      type="checkbox"
                      id="creditcard"
                      name="creditcard"
                      onChange={this.handlePaymentMethods}
                      checked={this.state.paymentMethod === 'creditcard' ? true : false}
                    />
                    <label className="text-center" htmlFor="creditcard">
                      Credit Card
                    </label>
                  </div>
                </Col> : ''
                }

                {
                  league && league.payment && league.payment.paymentMethod.indexOf(2) !== -1 ? 
                  <Col sm={3} xs={6}>
                  <div className="chk_box">
                    <Input
                      // disabled={isPaymentDisabled || this.state.paymentForm.paymentType === 2}
                      style={{cursor: isPaymentDisabled ? 'not-allowed' : 'pointer'}}
                      type="checkbox"
                      id="venmo"
                      name="venmo"
                      onChange={this.handlePaymentMethods}
                      checked={this.state.paymentMethod === 'venmo'}
                    />
                    <label className="text-center" htmlFor="venmo">
                      Venmo
                    </label>
                  </div>
                </Col> : ''
                }
              </Row>
            </div>
          </Col>
        </Row>
       
          <div className={`custom_popup ${this.state.Creditpopup ? 'open' : ''}`}>
              <div className="popup_pad popup_510 p-0">
                <div className="inner_popup p-0">
                  <span className="close_popup" onClick={this.closeStripePopup}><img src="/web_images/close.svg" alt="close"/></span>
                  <div className="">

                  <label className="text-center" htmlFor="creditcard">
                      Credit Card
                    </label>
                    <div className="stripe_payment">
                      <StripePayment 
                        amount={this.state.payment} 
                        onPaymentSuccess={this.stripePaymentSuccess} 
                        onPaymentError={this.stripePaymentError}/>
                    </div>
                  </div>
                </div>
              </div>
          </div>
          <div className={`custom_popup ${this.state.Venmopopup ? 'open' : ''}`}>
              <div className="popup_pad popup_510 p-0">
                <div className="inner_popup p-0">
                  <span className="close_popup" onClick={this.closeVenmoPopup}><img src="/web_images/close.svg" alt="close"/></span>
                  <div className="">
                    <div className="stripe_payment">
                      <VenmoPayment 
                        amount={this.state.payment} 
                        sendNanceTokenToServer={this.sendNanceTokenToServer}
                      />
                    </div>
                  </div>
                </div>
              </div>
          </div>
          <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={2000} />
      </div>
    );
  }
}


LeaguePayment.propTypes = {
  fetchPaymentIntent: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  leaguePayment: state.league,
});

export default connect(mapStateToProps, { fetchPaymentIntent })(LeaguePayment);
