import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  fetchPaymentIntent,
  joinLeague,
} from "../../../saga/actions/League.action";
import { Row, Col, Label, FormGroup, Input } from "reactstrap";
import StripePayment from "../Payment/Stripe";
import VenmoPayment from "../Payment/Venmo";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SimpleReactValidator from "simple-react-validator";

class EventPayment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      paymentForm: {
        couponCode: "",
        paymentType: "",
        paymentMethod: "",
        payments: [],
        managePayMethod: false,
      },
      payment: 0,
      paymentMethod: "",
      Creditpopup: false,
      Venmopopup: false,
    };
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
  }

  componentDidMount() {
    const event = this.props.eventData;

    if (
      event.payment.paymentType.length === 1 &&
      event.payment.paymentType.indexOf(2) !== -1
    ) {
      this.setState({
        paymentForm: {
          ...this.state.paymentForm,
          paymentType: 2,
        },
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.leaguePaymentIntent) {
      if (nextProps.leaguePaymentIntent.paymentIntent) {
        localStorage.setItem(
          "stripeIntent",
          nextProps.leaguePaymentIntent.paymentIntent
        );
        nextProps.leaguePaymentIntent.paymentIntent = "";
      }

      if (nextProps.leaguePaymentIntent.paymentIntentError) {
        toast.error(nextProps.leaguePaymentIntent.paymentIntentError, {
          position: toast.POSITION.TOP_RIGHT,
        });
        // this.setState({
        //   paymentForm: {
        //     ...this.state.paymentForm,
        //     paymentType: ''
        //   }
        // })
        //this.props.history.push("/WebApp/detail")
        nextProps.leaguePaymentIntent.paymentIntentError = "";
      }
    }
  }

  handlePaymentTypes = (e) => {
    e.preventDefault();

    var amount = 0;
    var paymentType = JSON.parse(e.target.value);

    // console.log("paymentType:::---", paymentType);

    if (paymentType === 1 || paymentType === 2)
      amount = this.props.eventData.payment.feesTournament;
    else if (paymentType === 3)
      amount = this.props.eventData.payment.installments[0].price;
    else amount = 0;

    this.setState({
      payment: amount,
      paymentForm: {
        ...this.state.paymentForm,
        paymentType: paymentType,
      },
      managePayMethod: paymentType === 2 ? true : false,
    });

    this.props.handlePaymentPage("paymentType", paymentType);
    this.props.handlePaymentPage("amount", amount);

    var payment = {
      amount: amount,
      objetId: this.props.eventData._id,
      module: 1,
      method: "stripe",
    };
    this.props.fetchPaymentIntent(payment);
  };

  handlePaymentMethods = (e) => {
    e.preventDefault();

    if (this.validator.allValid()) {
      var name = e.target.name;
      this.setState({
        paymentMethod: name,
        paymentForm: {
          ...this.state.paymentForm,
          paymentMethod: name === "creditcard" ? 1 : 2,
        },
      });

      this.props.handlePaymentPage(
        "paymentMethod",
        name === "creditcard" ? 1 : 2
      );

      if (name === "creditcard") this.setState({ Creditpopup: true });
      else this.setState({ Venmopopup: true });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  closeStripePopup = () => {
    this.setState({
      Creditpopup: false,
    });
  };

  closeVenmoPopup = () => {
    this.setState({
      Venmopopup: false,
    });
  };

  stripePaymentSuccess = (payment) => {
    this.setState({
      Creditpopup: false,
    });
    toast.success("Paymet Sucsess.", {
      position: toast.POSITION.TOP_RIGHT,
    });
    this.props.showPaymentButton(payment);
    // this.props.stripePaymentSuccessful(payment)
  };

  register = (e) => {
    this.props.stripePaymentSuccessful(e);
  };

  stripePaymentError = (error) => {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  sendNanceTokenToServer = (nanceToken) => {
    this.setState({
      Venmopopup: false,
    });
    this.props.sendNanceTokenToServer(nanceToken);
  };

  onHandleChange = (e) => {
    e.preventDefault();

    const { name, value } = e.target;
    this.setState({
      paymentForm: {
        ...this.state.paymentForm,
        [name]: value,
      },
    });
    this.props.handlePaymentPage("couponCode", value);
  };

  render() {
    const event = this.props.eventData;
    var isPaymentDisabled = true;
    // console.log("event:---", event);
    if (
      event.payment.paymentType.length === 1 &&
      event.payment.paymentType.indexOf(2) !== -1
    ) {
      isPaymentDisabled = true;
    } else {
      isPaymentDisabled = false;
    }

    const { paymentForm } = this.state;
    return (
      <div className="bg_white web_description_box">
        <div className="web_join_title text-center">
          <h2>Payment</h2>
        </div>
        <Row>
          <Col lg={6} className="web_event_join">
            <div className="create_event_row">
              <FormGroup>
                <Label>Apply Coupon Code</Label>
                <input
                  type="text"
                  name="couponCode"
                  placeholder="#EDF458"
                  onChange={this.onHandleChange}
                />
              </FormGroup>
            </div>
            <div className="create_event_row mb-4">
              <FormGroup>
                <Label>Payment Type</Label>
                <div className="select_custom">
                  <select
                    onChange={this.handlePaymentTypes}
                    value={paymentForm.paymentType}
                  >
                    <option selected>Please select paymentType</option>
                    {event &&
                      event.payment &&
                      event.payment.paymentType.indexOf(1) !== -1 ? (
                        <option value={1}>
                          Full Payment ( ${event.payment.feesTournament} )
                        </option>
                      ) : null}
                    {event &&
                      event.payment &&
                      event.payment.paymentType.indexOf(2) !== -1 ? (
                        <option value={2}>
                          Day Of ( ${event.payment.feesTournament} )
                        </option>
                      ) : null}
                    {event &&
                      event.payment &&
                      event.payment.paymentType.indexOf(3) !== -1 &&
                      event.payment.installments.length > 0 ? (
                        <option value={3}>
                          Pay First Installment ( $
                          {event.payment.installments[0].price} )
                        </option>
                      ) : null}
                  </select>
                  <span className="angle_down">
                    <i className="fa fa-angle-down" aria-hidden="true"></i>
                  </span>
                </div>
                <span style={{ color: "red" }}>
                  {this.validator.message(
                    "PaymentType",
                    paymentForm.paymentType,
                    "required"
                  )}
                </span>
                <span style={{ color: "red" }}>
                  {this.props.paymentTypeError}
                </span>
              </FormGroup>
            </div>
          </Col>
          {!this.state.managePayMethod && (
            <Col lg={12}>
              <div className="create_event_row">
                <label>Payment Method</label>
                <Row>
                  {event &&
                    event.payment &&
                    event.payment.paymentMethod.indexOf(1) !== -1 ? (
                      <Col sm={3} xs={6}>
                        <div className="chk_box">
                          <Input
                            disabled={
                              isPaymentDisabled ||
                              this.state.paymentForm.paymentType === 2
                            }
                            className={
                              isPaymentDisabled ||
                                this.state.paymentForm.paymentType === 2
                                ? "not_allow_btn"
                                : ""
                            }
                            type="checkbox"
                            id="creditcard"
                            name="creditcard"
                            onChange={this.handlePaymentMethods}
                            checked={
                              this.state.paymentMethod === "creditcard"
                                ? true
                                : false
                            }
                          />
                          <label className="text-center" htmlFor="creditcard">
                            Credit Card
                        </label>
                        </div>
                      </Col>
                    ) : (
                      ""
                    )}

                  {event &&
                    event.payment &&
                    event.payment.paymentMethod.indexOf(2) !== -1 ? (
                      <Col sm={3} xs={6}>
                        <div className="chk_box">
                          <Input
                            disabled={
                              isPaymentDisabled ||
                              this.state.paymentForm.paymentType === 2
                            }
                            style={{
                              cursor: isPaymentDisabled
                                ? "not-allowed"
                                : "pointer",
                            }}
                            type="checkbox"
                            id="venmo"
                            name="venmo"
                            onChange={this.handlePaymentMethods}
                            checked={this.state.paymentMethod === "venmo"}
                          />
                          <label className="text-center" htmlFor="venmo">
                            Venmo
                        </label>
                        </div>
                      </Col>
                    ) : (
                      ""
                    )}
                </Row>
              </div>
            </Col>
          )}
          {this.state.managePayMethod && (
            <Col lg={12}>
              <button type="button" className="btn_bg" onClick={this.register}>
                Register
              </button>
            </Col>
          )}
        </Row>

        <div className={`custom_popup ${this.state.Creditpopup ? "open" : ""}`}>
          <div className="popup_pad popup_510 p-0">
            <div className="inner_popup p-0">
              <span className="close_popup" onClick={this.closeStripePopup}>
                <img src="/web_images/close.svg" alt="close" />
              </span>
              <div className="">
                {/* <label className="text-center" htmlFor="creditcard">
                      Credit Card
                    </label> */}
                <div className="stripe_payment">
                  <StripePayment
                    amount={this.state.payment}
                    onPaymentSuccess={this.stripePaymentSuccess}
                    onPaymentError={this.stripePaymentError}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={`custom_popup ${this.state.Venmopopup ? "open" : ""}`}>
          <div className="popup_pad popup_510 p-0">
            <div className="inner_popup p-0">
              <span className="close_popup" onClick={this.closeVenmoPopup}>
                <img src="/web_images/close.svg" alt="close" />
              </span>
              <div className="">
                <div className="stripe_payment">
                  <VenmoPayment
                    amount={this.state.payment}
                    sendNanceTokenToServer={this.sendNanceTokenToServer}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={2000} />
      </div>
    );
  }
}

EventPayment.propTypes = {
  fetchPaymentIntent: PropTypes.func.isRequired,
  joinLeague: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  leaguePaymentIntent: state.league,
});

export default connect(mapStateToProps, {
  fetchPaymentIntent,
  joinLeague,
})(EventPayment);
