import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class HotelSlider extends React.Component {
  render() {
    var settings = {
      dots: true,
      arrows: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    };

    return (
      <div className="web_description_box">
        <div className="web_desc_title">
          <h3>Hotels Information</h3>
        </div>
        <div className="web_hotel_slidermain">
          <Slider {...settings}>
            {this.props.hotels &&
              this.props.hotels.map(function (hotel, index) {
                return (
                  <div key={index} className="web_hotel_slide">
                    <img
                      src="https://artoon-taxuz.s3.amazonaws.com/7/b7c3fd18ba90aec6eb05d11e6c942743.jpg"
                      alt="hotel"
                      className="rounded"
                    />
                    <div className="web_hotel_address">
                      <p>{hotel.hotelAddress}</p>
                    </div>
                  </div>
                );
              })}
          </Slider>
        </div>
      </div>
    );
  }
}

export default HotelSlider;
