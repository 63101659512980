import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { GetPolicy } from '../saga/actions/Common.action'
import Header from "./WebComponents/Header";
import Footer from "./WebComponents/Footer";
import Loader from "react-loader-spinner";

class PrivacyPolicy extends Component {

    constructor(props) {
        super(props);
        this.state = {
            pl: "",
            isLoading: true,
            isMobile: false
        }
    }

    componentDidMount() {
        if (this.props.location.search === "?mobile") {
            this.setState({ isMobile: true })
        }
        this.props.GetPolicy();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.pl && nextProps.pl.data) {
            this.setState({
                pl: nextProps.pl.data,
                isLoading: false
            })
        }
    }

    render() {
        return (
            <div className="web_wrapper">
                <Loader
                    className="ab_loader"
                    type="Circles"
                    color="#02A8A8"
                    height={80}
                    width={80}
                    visible={this.state.isLoading}
                />
                <div className="web_main">
                    {
                        !this.state.isMobile ? <div className="web_banner">
                            <img src="/web_images/banner.png" alt="banner" />
                        </div> : ""
                    }
                    <div className="web_container">
                        {
                            !this.state.isMobile ? <Header history={this.props.history} /> : ""
                        }
                    </div>
                </div>
                <div className="container_900">
                    <div className="text-center faq_title">
                        <h1>Privacy Policy</h1>
                    </div>
                    <div {...{ className: 'textTC' }}>
                        <p
                            dangerouslySetInnerHTML={{
                                __html: this.state.pl
                            }}></p>
                    </div>
                </div>
                {
                    !this.state.isMobile ? <Footer /> : ""
                }

            </div>
        );
    }
}

// export default PrivacyPolicy;

PrivacyPolicy.propTypes = {
    GetPolicy: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    pl: state.common.pl,
});

export default connect(mapStateToProps, { GetPolicy })(PrivacyPolicy);