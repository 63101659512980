import {
  UPLOAD_WAIVERS_REQUEST,
  UPLOAD_WAIVERS_SUCCESS,
  UPLOAD_WAIVERS_ERROR,

  UPLOAD_INSURANCE_REQUEST,
  UPLOAD_INSURANCE_SUCCESS,
  UPLOAD_INSURANCE_ERROR,

  IMAGE_DELETE_REQUEST,
  IMAGE_DELETE_SUCCESS,
  IMAGE_DELETE_ERROR,

  GET_FAQS_REQUEST,
  GET_FAQS_SUCCESS,
  GET_FAQS_ERROR,

  GET_TC_REQUEST,
  GET_TC_SUCCESS,
  GET_TC_ERROR,

  GET_PL_REQUEST,
  GET_PL_SUCCESS,
  GET_PL_ERROR,
} from '../constants/Common.const';

const initialState = {
  waivers: [],
  waiversError: null,
  waiversLoader: false,
  isWaiversUploaded: false,

  faqs: [],
  faqsError: null,
  faqsLoader: false,
  isFaqsloaded: false,

  tc: [],
  tcError: null,
  tcLoader: false,
  isTCloaded: false,

  pl: [],
  plLoader: false,

  insurance: [],
  insuranceError: null,
  insuranceLoader: false,
  isInsuranceUploaded: false,

  isDeleted: false,
  isDeletedError: null,
  isDeletedLoader: false,
};

export default function (state = initialState, action) {
  switch (action.type) {

    // Upload League Waivers

    case UPLOAD_WAIVERS_REQUEST:
      return {
        ...state,
        waiversLoader: true
      };
    case UPLOAD_WAIVERS_SUCCESS:
      return {
        ...state,
        isWaiversUploaded: true,
        waivers: action.payload,
        waiversLoader: false
      };
    case UPLOAD_WAIVERS_ERROR:
      return {
        ...state,
        waiversError: action.error.message,
        waiversLoader: false
      };

    // Upload League Waivers

    case UPLOAD_INSURANCE_REQUEST:
      return {
        ...state,
        insuranceLoader: true
      };
    case UPLOAD_INSURANCE_SUCCESS:
      return {
        ...state,
        isInsuranceUploaded: true,
        insurance: action.payload,
        insuranceLoader: false
      };
    case UPLOAD_INSURANCE_ERROR:
      return {
        ...state,
        insuranceError: action.error.message,
        insuranceLoader: false
      };

    // Upload League Insurance

    case IMAGE_DELETE_REQUEST:
      return {
        ...state,
        isDeletedLoader: true
      };
    case IMAGE_DELETE_SUCCESS:
      return {
        ...state,
        isDeleted: true,
        isDeletedLoader: false
      };
    case IMAGE_DELETE_ERROR:
      return {
        ...state,
        isDeletedError: action.error,
        isDeletedLoader: false
      };
    case GET_FAQS_REQUEST:
      return {
        ...state,
        faqsLoader: true
      };
    case GET_FAQS_SUCCESS:
      return {
        ...state,
        faqs: action.payload,
        faqsLoader: false
      };
    case GET_FAQS_ERROR:
      return {
        ...state,
        faqs: action.error.message,
        faqsLoader: false
      };
    case GET_TC_REQUEST:
      return {
        ...state,
        tcLoader: true
      };
    case GET_TC_SUCCESS:
      return {
        ...state,
        tc: action.payload,
        tcLoader: false
      };
    case GET_TC_ERROR:
      return {
        ...state,
        tc: action.error.message,
        tcLoader: false
      };
    case GET_PL_REQUEST:
      return {
        ...state,
        plLoader: true
      };
    case GET_PL_SUCCESS:
      return {
        ...state,
        pl: action.payload,
        plLoader: false
      };
    case GET_PL_ERROR:
      return {
        ...state,
        pl: action.error.message,
        plLoader: false
      };
    default:
      return state;
  }
}