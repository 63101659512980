import React from 'react';
import {useStripe, useElements, CardElement} from '@stripe/react-stripe-js';

import CardSection from './CardSection';

export default function CheckoutForm(props) {
  const stripe = useStripe();
  const elements = useElements();
  const stripeIntent = localStorage.getItem('stripeIntent');

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const result = await stripe.confirmCardPayment(stripeIntent, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: 'Jenny Rosen',
        },
      }
    });

    if (result.error) {
      props.paymentError(result.error.message)
    } else {
      if (result.paymentIntent.status === 'succeeded') {
        localStorage.removeItem('stripeIntent')
        props.paymentSuccess(result.paymentIntent)
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <CardSection />
      <button disabled={!stripe}>Pay</button>
    </form>
  );
}