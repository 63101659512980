import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Header from "./WebComponents/Header";
import { fetchLeagueById } from '../saga/actions/League.action';
import { Row, Col } from 'reactstrap';
import LocationBox from "./WebComponents/LocationBox";
import SponsorBox from "./WebComponents/SponsorBox";
import CompanyEventBox from "./WebComponents/CompanyEventBox";
import moment from 'moment'
import Login from "./WebComponents/Login"
import {checkLoginValidation, getUserRole} from '../saga/helpers/authToken';



class SuperLeagueDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userRole: getUserRole(),
      league : '',
    }
    this.getEventType = this.getEventType.bind(this);
    this.getDivision = this.getDivision.bind(this);

    this.payment = this.payment.bind(this);
    this.selectSuper8 = this.selectSuper8.bind(this);
    this.isShowJoin = this.isShowJoin.bind(this);
  }

  componentWillMount() {
    this.props.fetchLeagueById();
  }

  componentWillReceiveProps(nextProps) {

    if(nextProps.leagueData)
    {
      if(nextProps.leagueData.league && nextProps.leagueData.isLeagueLoaded)
      {
        nextProps.leagueData.isLeagueLoaded = false
        var leagueData= nextProps.leagueData.league;  
  
        this.setState({
          league: leagueData
        })
      }
    }
  }

  getEventType(eventTeamType) {
    if(eventTeamType === 1)
      return 'Team'
    else
      return 'Showcase'
  }

  getDivision(division) {
    if(division){
      return division.map((div)=>{
        return div.title;
     }).join(",")
    }
     else{
       return ""
     }
  }

  payment = () => {
    var isloggedIn = checkLoginValidation();

    if(isloggedIn)
    {
      this.props.history.push("/WebApp/league/join")
    }
    else
    {
      this.setState({
        isShowLogin: true
      })
    }
  }

  selectSuper8() {
    var isloggedIn = checkLoginValidation();
    if(isloggedIn)
    {
      this.props.history.push("/WebApp/selectSuper8")
    }
    else
    {
      this.setState({
        isShowLogin: true
      })
    }
  }

  popupClose = () => {
    this.setState({
      isShowLogin: false
    })
  }

  isShowJoin = () => {
      if(this.state.userRole === 'Player' || this.state.userRole === 'Fan')
        return null;
      else
      {
        if(this.state.league.selPer === 4)
          return (<button className="btn_green" style={{cursor: 'not-allowed'}} disabled  >Joined</button>)
        else
          return (<button className="btn_green" style={this.state.league.selEvent !== 8 ? {cursor: 'not-allowed'} : {cursor: 'pointer'}} data-toggle={this.state.league.selEvent !== 8 ? "tooltip": '' } title={this.state.league.selEvent !== 8 ? "Select 8 Events to Join !": '' } disabled={this.state.league.selEvent !== 8} onClick={this.payment} >Join</button>)
      }
  }

  render() {

    const  league  = this.state.league;
      return (
      <div className="web_wrapper">
        <div className="web_main inner_pages">
          <div className="web_banner">
            <img src="/web_images/banner.png" alt="banner" />
          </div>
          <div className="web_container">
          <Header history={this.props.history}/>
          </div>
        </div>
        <div className="web_container_1290">
          <div className="web_eventOuterList">
        <div className="web_eventlist_wrapper d-flex flex-wrap align-items-center">
          <div className="web_eventList_img super8_league_img event_card_img">
            <img src={league.leagueImages && league.leagueImages.filePath ? league.leagueImages && league.leagueImages.filePath : "/web_images/Default_Banner.png"} alt="event banner" />
            <span className="event_card_address">{ league.location && league.location.city }</span>
          </div>
          <div className="web_eventList_content d-flex flex-wrap align-items-center">
            <div className="web_eventList_data d-flex flex-wrap align-items-center">
              <div className="web_eventList_logo">
                <img src={ league.leagueLogo && league.leagueLogo.filePath ? league.leagueLogo && league.leagueLogo.filePath : "/web_images/Default_Banner.png"} alt="event logo" />
              </div>
              <div className="web_eventList_detail">
                <h1>{ league.leagueTitle }</h1>
                <div className="web_eventList_icons d-flex flex-wrap">
                  <span className="common_softball_icon d-flex align-items-center">{ league.eventType === 1 ? 'Baseball' : 'Softball' }</span>
                  <span className="common_date_icon d-flex align-items-center">{moment(league.startDate).format('DD-MMM')} - {moment(league.endDate).format('DD-MMM')}</span>
                  <span className="common_team_icon d-flex align-items-center">{league.teamLimit} Teams</span>
                </div>
              </div>
            </div>
            <div className="web_eventList_join">
              <h2>${league.payment && league.payment.feesLeague} <span>Fee to Join</span></h2>
              {
                this.isShowJoin()              
              }
             
              <button className="btn_bg2" onClick={this.selectSuper8}>Add to Super 8 ({league.selEvent})</button>
            </div>
          </div>
          <div>
            {
              this.state.isShowLogin ? <Login history={this.props.history} class='custom_popup open auth_form m-0' onClose={this.popupClose} /> : ''
            }
          </div>
        </div>

            
          </div>
          <Row>
            <Col lg={8}>
              <div className="web_description_box">
                <div className="web_desc_title">
                  <h3>Details</h3>
                </div>
                <div className="web_list_data d-flex flex-wrap">
                  <div className="web_list_data_box text-center">
                    <label>Event Type</label>
                      <h3>{this.getEventType( league.eventTeamType)}</h3>
                  </div>
                  <div className="web_list_data_box text-center">
                    <label>Division</label>
                    <h3>{this.getDivision(league.div)}</h3>
                  </div>
                  <div className="web_list_data_box text-center">
                    <label>Age Group</label>
                      <h3>{ league.ageGroup }</h3>
                  </div>
                  <div className="web_list_data_box text-center">
                    <label>Location</label>
                      <h3>{ league.location && league.location.city }</h3>
                  </div>

                  {
                    league.setting && league.settings.spotAvailable === true ? 
                    <div className="web_list_data_box text-center">
                      <label>Spots Available</label>
                        <h3>{ league.teamLimit }</h3>
                    </div> : ''
                  }


                </div>
                <div className="web_desc_title">
                  <h3> League Description</h3>
                </div>
                  <p>{ league.information && league.information.leagueDet }</p>
              </div>
              <div className="web_description_box">
                <div className="web_desc_title">
                  <h3>League Format</h3>
                </div>
                <p>{ league.information && league.information.leagueFormat }</p>
              </div>
              <div className="web_description_box">
                <div className="web_desc_title">
                  <h3>League Rules</h3>
                </div>
                <p>{ league.information && league.information.leagueRule }</p>
              </div>
            </Col>
            <Col lg={4}>
              <LocationBox location={league.location}/>
              <CompanyEventBox company={league.dsCompanies}/>
              {league.sponsor && <SponsorBox sponsor={league.sponsor}/>}
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

SuperLeagueDetail.propTypes = {
  fetchLeagueById: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  leagueData: state.league
});

export default connect(mapStateToProps, { fetchLeagueById })(SuperLeagueDetail);