import React from 'react';
import Cookies from 'universal-cookie';
import Login from "../WebComponents/Login"

import {
  Nav,
  Input,
  // Form,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';

const cookies = new Cookies();
class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      setIsOpen: false,
      search: false,
      menuToggle: false,
      isShowLogin: false
    }
  }

  toggle = () => {
    this.setState({
      setIsOpen: !this.state.setIsOpen
    })
  }

  toggleSearch = () => {
    this.setState({
      search: !this.state.search
    })
  }

  menuToggle = () => {
    this.setState({
      menuToggle: !this.state.menuToggle
    })
  }
  logOut = () => {

    this.setState({});
    cookies.remove("accessToken");
    cookies.remove("userProfile");
    cookies.remove("role");
    this.props.history.push("/");
    window.location.reload();
  }

  popupClose = () => {
    this.setState({
      isShowLogin: false
    })
  }

  logIN = () => {
    this.setState({
      isShowLogin: true
    })
  }
  clickFqas = (props) => {
    props.history.push("/WebApp/faqs")
  }
  clickTC = (props) => {
    props.history.push("/WebApp/termsandconditions")
  }
  clickPL = (props) => {
    props.history.push("/WebApp/privacypolicy")
  }

  render() {

    const userProfile = cookies.get('userProfile');
    return (
      <div className="header_wrapper d-flex flex-wrap justify-content-between align-items-center">
        <div className="diamond_logo">
          <NavLink href="/"><img src="/web_images/Logo.svg" alt="DiamondConnect logo" /></NavLink>
        </div>
        <div className="web_menu">
          <div className="web_menu_inner d-flex flex-wrap align-items-center">
            <div className="search_form">
              <div className={`web_search ${this.state.search ? 'active' : ''}`}>
                <i className="fa fa-search" onClick={this.toggleSearch}></i>
                <Input type="text" name="search" id="" placeholder="Search Here..." />
              </div>
            </div>
            <div className={`web_menu_toggle ${this.state.menuToggle ? "active" : ''}`}>
              <p className="web_menu_toggleBtn" onClick={this.menuToggle}>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
              </p>
              <Nav className="" >
                <NavItem>
                  <NavLink href="/">Events</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink>Download App</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink>Contact us</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink onClick={this.clickFqas.bind(this, this.props)}>FAQs</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink onClick={this.clickTC.bind(this, this.props)}>Terms & Conditions</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink onClick={this.clickPL.bind(this, this.props)}>Privacy Policy</NavLink>
                </NavItem>
                {
                  !userProfile ? <NavItem>
                    <NavLink onClick={this.logIN}> LogIn</NavLink>
                  </NavItem> : ""
                }

              </Nav>
            </div>
            {
              userProfile ? <UncontrolledDropdown nav inNavbar className="d-flex align-items-center">
                <span className="web_user"><img src={userProfile ? userProfile.profileUrl : '/web_images/banner.png'} alt="user" /></span>
                <DropdownToggle nav caret>
                  {userProfile ? userProfile.fname + ' ' + userProfile.lname : 'Coach Jim'}
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem onClick={this.logOut}>
                    Logout
                </DropdownItem>
                  {/* <DropdownItem>
                  Option 2
                </DropdownItem>
                <DropdownItem>
                  Reset
                </DropdownItem> */}
                </DropdownMenu>
              </UncontrolledDropdown> : ""
            }

          </div>
        </div>
        {
          this.state.isShowLogin ? <Login history={this.props.history} class='custom_popup open auth_form m-0' onClose={this.popupClose} /> : ''
        }
      </div>
    );
  }
}

export default Header;