import React from "react";
import { NavLink } from "reactstrap";

class CompanyEventBox extends React.Component {
  render() {
    const company = this.props.company;

    return (
      <div className="web_description_fullborderbox">
        <div className="web_desc_title">
          <h3>{company && company.name}</h3>
        </div>
        <div className="web_description_box_20">
          <div className="companyLogos">
            <img
              src={company && company.companyLogo[0].filePath}
              alt="company logo"
            />
          </div>
          <div className="company_social_link">
            <NavLink href={company && company.facebookUrl} className="fblink" target="_blank">
              <i className="fa fa-facebook"></i>
              {company && company.facebookLink}
            </NavLink>
            <NavLink href={company && company.twitterUrl} className="twtlink" target="_blank">
              <i className="fa fa-twitter" aria-hidden="true"></i>
              {company && company.twitterLink}
            </NavLink>
            <NavLink href={company && "mailto:" + company.email} className="emaillink">
              <i className="fa fa-envelope" aria-hidden="true"></i>
              {company && company.email}
            </NavLink>
          </div>
        </div>
      </div>
    );
  }
}

export default CompanyEventBox;
