export const UPLOAD_WAIVERS_REQUEST = 'UPLOAD_WAIVERS_REQUEST';
export const UPLOAD_WAIVERS_SUCCESS = 'UPLOAD_WAIVERS_SUCCESS';
export const UPLOAD_WAIVERS_ERROR   = 'UPLOAD_WAIVERS_ERROR';

export const UPLOAD_INSURANCE_REQUEST = 'UPLOAD_INSURANCE_REQUEST';
export const UPLOAD_INSURANCE_SUCCESS = 'UPLOAD_INSURANCE_SUCCESS';
export const UPLOAD_INSURANCE_ERROR   = 'UPLOAD_INSURANCE_ERROR';

export const IMAGE_DELETE_REQUEST = 'IMAGE_DELETE_REQUEST';
export const IMAGE_DELETE_SUCCESS = 'IMAGE_DELETE_SUCCESS';
export const IMAGE_DELETE_ERROR   = 'IMAGE_DELETE_ERROR';

export const GET_FAQS_REQUEST = 'GET_FAQS_REQUEST';
export const GET_FAQS_SUCCESS = 'GET_FAQS_SUCCESS';
export const GET_FAQS_ERROR   = 'GET_FAQS_ERROR';

export const GET_TC_REQUEST = 'GET_TC_REQUEST';
export const GET_TC_SUCCESS = 'GET_TC_SUCCESS';
export const GET_TC_ERROR   = 'GET_TC_ERROR';

export const GET_PL_REQUEST = 'GET_PL_REQUEST';
export const GET_PL_SUCCESS = 'GET_PL_SUCCESS';
export const GET_PL_ERROR   = 'GET_PL_ERROR';