import axios from 'axios';
import Cookies from 'universal-cookie';

import { API_Url } from '../../helpers/authToken'
import { 
    LOGIN_REQUEST,
    LOGIN_SUCCESS, 
    LOGIN_ERROR,
} from '../../constants/Authentication/Login.const';
import { 

    USER_INFO_REQUEST,
    USER_INFO_SUCCESS, 
} from '../../constants/Authentication/UpdateProfile.const';

export const login = (login) => dispatch => {

    dispatch({type: LOGIN_REQUEST})
    axios({
        method: 'POST',
        url: `${API_Url}user/login`,
        data: login,
        headers: {
        'Accept': 'application/json'}
        })

    .then(login => {
        const cookies = new Cookies();
        var date = new Date();
        date.setDate(date.getDate() + 1);
        cookies.set('accessToken', login.data.result.token, { pathname: '/', expires: date });
        cookies.set('role', login.data.result.role);
        var loginToken = login.data.result.token;

        //login.data.result.role
        dispatch({
            type: LOGIN_SUCCESS,
            payload: loginToken
        })
        dispatch({type: USER_INFO_REQUEST})
        axios({
            method: 'GET',
            url: `${API_Url}user/profile`,
            headers: {
                'x-auth-token': loginToken, 
                'Accept': 'application/json'}
                })
    
        .then(info => {

            const cookies = new Cookies();
            var date = new Date();
            date.setDate(date.getDate() + 1);

            var alllData = info.data.result;

            var userData = {
                fname: alllData.fname,
                lname: alllData.lname,
                profileUrl: alllData.profileUrl,
                role: alllData.profile.role
            }

            cookies.set('userProfile', userData, { pathname: '/', expires: date });
            
            dispatch({
                type: USER_INFO_SUCCESS,
                payload: info.data.result
            })
            window.location.reload();
            
        })

    })
    .catch((error) => {
        dispatch({type: LOGIN_ERROR, error: error.response.data.message})
    });
};