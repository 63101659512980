import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import "../node_modules/bootstrap/scss/bootstrap.scss";
import "./WebView/style/style.scss";


import store from './store';
import { Provider } from 'react-redux';
import history from './WebView/History/History'

import WebEvent from "./WebView/WebEvent";
import WebEventDetail from "./WebView/WebEventDetail";
import LeagueDetail from "./WebView/LeagueDetail";
import Super8Detail from "./WebView/Super8Detail";
import LeagueEventSelect from "./WebView/LeagueEventSelect";
import WebEventJoin from "./WebView/WebEventJoin";
import WebEventJoined from "./WebView/WebEventJoined";
import FAQs from "./WebView/FAQs";
import TearmsAndCondition from "./WebView/TearmsAndCondition";
import PrivacyPolicy from "./WebView/PrivacyPolicy";
import LeagueJoin from "./WebView/WebComponents/League/LeagueJoin";
import LeagueJoined from "./WebView/WebComponents/League/LeagueJoined";
import Login from "./WebView/WebComponents/Login";

const WebAppPrefix="/WebApp";
const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

class App extends Component {
  render() {
    return (
      <>
        <Provider store={store}>
        <BrowserRouter history={history}>
          <React.Suspense fallback={loading()}>
          <Switch>
              <Route exact path='/' name="Register Page" render={props => <WebEvent {...props} />} />
              <Route exact path={`${WebAppPrefix}/detail`} name="WebEventDetail" render={props => <WebEventDetail {...props} />} />
              <Route exact path={`${WebAppPrefix}/login`} name="Login" render={props => <Login {...props} />} />
              <Route exact path={`${WebAppPrefix}/selectSuper8`} name="LeagueEventSelect" render={props => <LeagueEventSelect {...props} />} component={LeagueEventSelect} />
              <Route exact path={`${WebAppPrefix}/join`} name="WebEventJoin" render={props => <WebEventJoin {...props} />} />
              <Route exact path={`${WebAppPrefix}/league/join`} name="LeagueJoin" render={props => <LeagueJoin {...props} />} />
              <Route exact path={`${WebAppPrefix}/league`} name="LeagueDetail" render={props => <LeagueDetail {...props} />} />
              <Route exact path={`${WebAppPrefix}/super8`} name="Super8Detail" render={props => <Super8Detail {...props} />} />
              <Route exact path={`${WebAppPrefix}/event/joined`} name="WebEventJoined" render={props => <WebEventJoined {...props} />} />
              <Route exact path={`${WebAppPrefix}/league/joined`} name="WebEventJoined" render={props => <LeagueJoined {...props} />} />
              <Route exact path={`${WebAppPrefix}/faqs`} name="FAQs" render={props => <FAQs {...props} />} />
              <Route exact path={`${WebAppPrefix}/termsandconditions`} name="TearmsAndCondition" render={props => <TearmsAndCondition {...props} />} />
              <Route exact path={`${WebAppPrefix}/privacypolicy`} name="PrivacyPolicy" render={props => <PrivacyPolicy {...props} />} />
              <Route exact path={`${WebAppPrefix}/faqs?mobile`} name="FAQs" render={props => <FAQs {...props} />} />
              <Route exact path={`${WebAppPrefix}/termsandconditions?mobile`} name="TearmsAndCondition" render={props => <TearmsAndCondition {...props} />} />
              <Route exact path={`${WebAppPrefix}/privacypolicy?mobile`} name="PrivacyPolicy" render={props => <PrivacyPolicy {...props} />} />
          </Switch>
          </React.Suspense>
        </BrowserRouter>
      </Provider>

      </>
    );
  }
}

export default App;
