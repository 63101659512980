import axios from 'axios';
import Cookies from 'universal-cookie';

import { API_Url, Auth_Key } from '../../helpers/authToken'
import { 

    USER_INFO_REQUEST,
    USER_INFO_SUCCESS, 
    USER_INFO_ERROR,

} from '../../constants/Authentication/UpdateProfile.const';

export const getUserInfo = () => dispatch => {
    dispatch({type: USER_INFO_REQUEST})
    axios({
        method: 'GET',
        url: `${API_Url}user/profile`,
        headers: {
            'x-auth-token': Auth_Key, 
            'Accept': 'application/json'}
            })

    .then(info => {
        const cookies = new Cookies();
        var date = new Date();
        date.setDate(date.getDate() + 1);
        cookies.set('userProfile', info.data.result, { pathname: '/', expires: date });
        dispatch({
            type: USER_INFO_SUCCESS,
            payload: info.data.result
        })
        setTimeout(() => {
            window.location.reload();
       }, 1000);
    })
    .catch((error) => {
        dispatch({type: USER_INFO_ERROR, error: error.response.data.message})
    });
};