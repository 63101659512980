import { 
  USER_INFO_REQUEST,
  USER_INFO_SUCCESS, 
  USER_INFO_ERROR,

  } from '../../constants/Authentication/UpdateProfile.const';
  
  const initialState = {

    isProfileLoaded: false,
    userProfile: null,
    userProfileError: null,
    userProfileLoader: false

  };
  
  export default function(state = initialState, action) {
    switch (action.type) {

      // Get User Profile

      case USER_INFO_REQUEST:
        return {
          ...state,
          userProfileLoader: true
        };
      case USER_INFO_SUCCESS:
        return {
          ...state,
          isProfileLoaded: true,
          userProfile: action.payload,
          userProfileLoader: false
        };
      case USER_INFO_ERROR:
        return {
          ...state,
          userProfileError: action.error,
          userProfileLoader: false
        };
  
      default:
        return state;
    }
  }