import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Header from "./WebComponents/Header";
// import EventListRow from "./WebComponents/EventListRow";
import { fetchLeagueById } from '../saga/actions/League.action';
import { fetchSuper8ById, updateSuper8 } from '../saga/actions/Super8.action';
import LeagueListRow from "./WebComponents/League/LeagueListRow";
import { Row } from 'reactstrap';
import EventCheck from "./WebComponents/super8/EventCheck";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {getUserRole} from '../saga/helpers/authToken';

class LeagueEventSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userRole: getUserRole(),
      selectedEventsCounter: 0,
      league: [],
      super8: [],
      event : '',
      eventTeams: '',
      Thankyou:false
    }
    this.getSelectedEventCounter = this.getSelectedEventCounter.bind(this);
    
  }

  componentDidMount() {
    this.getSuper8Data();
  }

  getSuper8Data = () => {
    var leagueId = localStorage.getItem('leagueId');
    this.props.fetchLeagueById();
    this.props.fetchSuper8ById(leagueId);
  }

  componentWillReceiveProps(nextProps)
  {
    if(nextProps.leagueData.league && nextProps.leagueData.isLeagueLoaded)
    {
      nextProps.leagueData.isLeagueLoaded = false
      var leagueData= nextProps.leagueData.league;  

      this.setState({
        league: leagueData
      })
    }

    if(nextProps.super8Data.allSuper8 && nextProps.super8Data.isAllSuper8Loaded)
    {
      nextProps.super8Data.isAllSuper8Loaded = false
      var super8Data= nextProps.super8Data.allSuper8.docs;  

      setTimeout(() => {
        this.setState({
          super8: super8Data
        },
          function () {
            this.getSelectedEventCounter();
          });
      }, 10)
    }

    if(nextProps.super8Data.isUpdated)
    {
      nextProps.super8Data.isUpdated = false
      this.getSuper8Data();
    }

    if(nextProps.super8Data.isUpdatedError)
    {
      nextProps.super8Data.isUpdatedError = ''
      toast.error("You can't select more then 8 events in league.", {
        position: toast.POSITION.TOP_RIGHT
      });
    }
  }


  Thankyou = () => {
    this.setState({
      Thankyou:true
    })
  }

  closePopup = () => {
    this.setState({
      Thankyou:false
    })
  }


  eventCheckedSelect = (super8Id, e) => {

    var event= this.state.super8.filter(_ => _._id === super8Id)[0]

    var super8 = {
      leageId: this.state.league._id,
      eventId: super8Id,
      flag: event.selPer === 0 ? true : false
    }
    this.props.updateSuper8(super8)

  }

  getSelectedEventCounter() {

    var Counter = 0
    this.state.super8.forEach(event => {
        if(event.selPer === 1)
        {
          Counter++;
        }
          
    });

    if(Counter === 8)
      this.Thankyou();


    this.setState({
      selectedEventsCounter: Counter
    })
  }

  backToLeagueDetail = () => {
    this.props.history.push("/WebApp/league")
  }

  render() {

    const league = this.state.league;
    const super8 = this.state.super8;

      return (
        <>
            <div className="web_wrapper">
              <div className="web_main inner_pages">
                <div className="web_banner">
                  <img src="/web_images/banner.png" alt="banner" />
                </div>
                <div className="web_container">
                <Header history={this.props.history}/>
                </div>
              </div>
              <div className="web_container_1290">
                <div className="web_eventOuterList">
                  {
                    league ? <LeagueListRow history={this.props.history} isHideJoinButton={true} leagueData={league} isGotoPayment={true}/> : ''
                  }
                </div>
              </div>  
                <div className="web_content web_container">
                    <div className="web_content_filter d-flex align-items-center justify-content-between">
                    <div className=" d-flex align-items-center">

                      {
                        this.state.userRole === 'Player' || this.state.userRole === 'Fan' ? '' : 
                          <>
                          <h2>Choose Super 8 Events </h2>
                          <div className="select_dropdown">
                            <span className="tag_num">{
                              this.state.selectedEventsCounter
                            }</span>
                          </div>
                          </>
                      }

                      </div>
                      <div>
                        <span className="link_color pointer" onClick={this.backToLeagueDetail}>Back</span>
                      </div>
                    </div>
                    <Row className="mb-4">
                    {
                      super8 && super8.map((event, index) => <EventCheck isShowCheck={league.selPer === 4 ? false : true} key={index} history={this.props.history} super8Data={event} eventSelect={this.eventCheckedSelect} /> )
                    }
                  </Row>
                </div>
            </div>
            <div className={`custom_popup ${this.state.Thankyou ? 'open' : ''}`}>
              <div className="popup_pad popup_510 p-0">
                <div className="inner_popup text-center">
                  <span className="close_popup" onClick={this.closePopup}><img src="/web_images/close.svg" alt="close"/></span>
                  <div className="delete_player_container">
                    <h2 className="green">Thank You </h2>
                    <p>You have selected {this.state.selectedEventsCounter} events for registration.</p>
                  </div>
                </div>
                <div className="deletes_buttons">
                  <button onClick={this.backToLeagueDetail} className="yes">League Detail</button>
                  <button onClick={this.closePopup}>Edit Selection</button>
                </div>
              </div>
            </div>
            <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={3000} />
         </>   
    );
  }
}


LeagueEventSelect.propTypes = {
  fetchSuper8ById: PropTypes.func.isRequired,
  fetchLeagueById: PropTypes.func.isRequired,
  updateSuper8: PropTypes.func.isRequired,

};

const mapStateToProps = state => ({
  leagueData: state.league,
  super8Data: state.super8
});

export default connect(mapStateToProps, { fetchSuper8ById, fetchLeagueById, updateSuper8 })(LeagueEventSelect);