
export const FETCH_ALL_LEAGUES_REQUEST = 'FETCH_ALL_LEAGUES_REQUEST';
export const FETCH_ALL_LEAGUES_SUCCESS = 'FETCH_ALL_LEAGUES_SUCCESS';
export const FETCH_ALL_LEAGUES_ERROR   = 'FETCH_ALL_LEAGUES_ERROR';

export const JOIN_REQUEST = 'JOIN_REQUEST';
export const JOIN_SUCCESS = 'JOIN_SUCCESS';
export const JOIN_ERROR   = 'JOIN_ERROR';

export const FETCH_LEAGUE_BY_ID_REQUEST = 'FETCH_LEAGUE_BY_ID_REQUEST';
export const FETCH_LEAGUE_BY_ID_SUCCESS = 'FETCH_LEAGUE_BY_ID_SUCCESS';
export const FETCH_LEAGUE_BY_ID_ERROR   = 'FETCH_LEAGUE_BY_ID_ERROR';

export const FETCH_PAYMENT_INTENT_REQUEST = 'FETCH_PAYMENT_INTENT_REQUEST';
export const FETCH_PAYMENT_INTENT_SUCCESS = 'FETCH_PAYMENT_INTENT_SUCCESS';
export const FETCH_PAYMENT_INTENT_ERROR   = 'FETCH_PAYMENT_INTENT_ERROR';

export const FETCH_VENMO_TOKEN_REQUEST = 'FETCH_VENMO_TOKEN_REQUEST';
export const FETCH_VENMO_TOKEN_SUCCESS = 'FETCH_VENMO_TOKEN_SUCCESS';
export const FETCH_VENMO_TOKEN_ERROR   = 'FETCH_VENMO_TOKEN_ERROR';

export const SEND_NONCE_REQUEST = 'SEND_NONCE_REQUEST';
export const SEND_NONCE_SUCCESS = 'SEND_NONCE_SUCCESS';
export const SEND_NONCE_ERROR   = 'SEND_NONCE_ERROR';

export const PAYMENT_COMPLETE_REQUEST = 'PAYMENT_COMPLETE_REQUEST';
export const PAYMENT_COMPLETE_SUCCESS = 'PAYMENT_COMPLETE_SUCCESS';
export const PAYMENT_COMPLETE_ERROR   = 'PAYMENT_COMPLETE_ERROR';

export const EVENT_REGISTRATION_REQUEST = 'EVENT_REGISTRATION_REQUEST';
export const EVENT_REGISTRATION_SUCCESS = 'EVENT_REGISTRATION_SUCCESS';
export const EVENT_REGISTRATION_ERROR   = 'EVENT_REGISTRATION_ERROR'

