import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchTopEvents, fetchAllEvents, fetchLocalEvents } from '../saga/actions/Events.action';
import { fetchLeagueById } from '../saga/actions/League.action';
// import {checkLoginValidation} from '../saga/helpers/authToken';
import Cookies from 'universal-cookie';
import Header from "./WebComponents/Header";
import Footer from "./WebComponents/Footer";
import LeagueListRow from "./WebComponents/League/LeagueListRow";
import Login from "./WebComponents/Login"
import { Row, NavLink,Col } from 'reactstrap';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


import EventBox from "./WebComponents/EventBox"


class EventsWeb extends React.Component {
  constructor(props) {
    super(props);
    const cookies = new Cookies();
    this.state = {
      isShowLogin: false,
      role : cookies.get("role"),
      event : '',
      eventTypeFilter: 0,
      isPresentFilter: 0,
      eventTypeFilter1: 0,
      isPresentFilter1: 0,
      eventTypeFilter2: 0,
      isPresentFilter2: 0,
      league: [],
      topEvents: [],
      topEventsError: '',
      allEvents: [],
      allEventsError: '',
      localEvents: [],
      localEventsError: '',
      month: [],
      curMonth: "",
      days: [],
      filterdEvents:[],
      activeMonth:new Date().getMonth(),
      activeDay:0
    }
    this.getAllEvents = this.getAllEvents.bind(this);
    this.getTopEvents = this.getTopEvents.bind(this);
    this.getLocalEvents = this.getLocalEvents.bind(this);
    this.filteEventWithDate = this.filteEventWithDate.bind(this);
    this.checkLogin = this.checkLogin.bind(this);
  }

  componentDidMount() {
    this.getTopEvents();
    this.getAllEvents();
    this.getLocalEvents();
    this.props.fetchLeagueById();
  }

  popupClose = () => {
    this.setState({
      isShowLogin: false
    })
  }

  getTopEvents() {
    var filter = {
      type: this.state.eventTypeFilter,
      isPresent: this.state.isPresentFilter
    }
    this.props.fetchTopEvents(filter);
  }

  getAllEvents() {
    var filter = {
      type: this.state.eventTypeFilter1,
      isPresent: this.state.isPresentFilter1
    }
    this.props.fetchAllEvents(filter);
  }

  getLocalEvents() {

    var self = this;
    
    navigator.geolocation.getCurrentPosition(function(position) {
      var filter = {
        type: self.state.eventTypeFilter2,
        isPresent: self.state.isPresentFilter2,
        lat: position.coords.latitude,
        long: position.coords.longitude
      }

      self.props.fetchLocalEvents(filter);
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.eventData) {

      if (nextProps.eventData.isTopEventsLoaded) {
        nextProps.eventData.isTopEventsLoaded = false
        const topEvents = nextProps.eventData.topEvents.filter(_ => _.isFeatured === true)
        this.setState({
          topEvents: topEvents
        })
        nextProps.eventData.topEvents = []
      }

      if (nextProps.eventData.isAllEventsLoaded) {
        nextProps.eventData.isAllEventsLoaded = false
        const allEvents = nextProps.eventData.allEvents
        this.setState({
          allEvents: allEvents,
          filterdEvents:allEvents
        })
        nextProps.eventData.allEvents = []
      }

      if (nextProps.eventData.isLocalEventsLoaded) {
        nextProps.eventData.isLocalEventsLoaded = false
        const localEvents = nextProps.eventData.localEvents
        this.setState({
          localEvents: localEvents,
          localFilterEvents:localEvents
        })
        nextProps.eventData.localEvents = []
      }

      if (nextProps.eventData.topEventsError) {
        this.setState({
          topEventsError: nextProps.eventData.topEventsError
        })
      }
    }

    if(nextProps.leagueData.league && nextProps.leagueData.isLeagueLoaded)
    {
      nextProps.leagueData.isLeagueLoaded = false
      var leagueData= nextProps.leagueData.league;  

      this.setState({
        league: leagueData
      })
    }

  }

  monthCLick = (event) => {
    console.log(event.target.id);
    this.setState({
      activeMonth: JSON.parse(event.target.id),
      activeDay:0
    });
    var filter= this.state.allEvents.filter(_=> (new Date(_.startDate).getMonth() === JSON.parse(event.target.id)))
    var localFilter= this.state.localEvents.filter(_=> (new Date(_.startDate).getMonth() === JSON.parse(event.target.id)))
    this.setState({
      filterdEvents: filter,
      localFilterEvents: localFilter
    })
  }

  eventDetail = () => {
    console.log("click",this.props.history);
    this.props.history.push("/WebApp/detail")

  }

  handleFilterChange = (e) => {

    const { name, value } = e.target;

    setTimeout(() => {
      this.setState({
        [name]: JSON.parse(value)
      },
        function () {
          this.getTopEvents();
        });
    }, 10)

  }
  filteEventWithDate = (e) =>{
    var filter= this.state.allEvents.filter(_=> (new Date(_.startDate).getDate()=== e.target.value) && (new Date(_.startDate).getMonth() === this.state.activeMonth))
    var localFilter= this.state.allEvents.filter(_=> (new Date(_.startDate).getDate()=== e.target.value) && (new Date(_.startDate).getMonth() === this.state.activeMonth))
    this.setState({
      filterdEvents:filter,
      localFilterEvents: localFilter,
      activeDay: e.target.value})
  }

  handleFilterChangeForAllEvents = (e) => {

    const { name, value } = e.target;

    setTimeout(() => {
      this.setState({
        [name]: JSON.parse(value)
      },
        function () {
          this.getAllEvents();
        });
    }, 10)

  }

  handleFilterChangeForLocalEvents = (e) => {

    const { name, value } = e.target;

    setTimeout(() => {
      this.setState({
        [name]: JSON.parse(value)
      },
        function () {
          this.getLocalEvents();
        });
    }, 10)

  }

  
  checkLogin = () => {
    this.setState({
      isShowLogin: true
    })
  }

  render() {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    var date = new Date();
    var year = date.getFullYear()
    const itemMonth = months.map((item , index) => {
      var self = this;
      return <li key={item} id={index} className={self.state.activeMonth  === index ? 'active' : ''} onClick={self.monthCLick}  >{item}</li>
    });

    var totalDays = function _getDates(year, month) {
      return new Date(year, month, 0).getDate();
    }
    var total = totalDays(year, this.state.activeMonth + 1);

    var list = [];
    for (var i = 1; i <= total; i++) {
      list.push(i)
    }
    var dateList=[];
    var listLI= [];
   list.map(function (e) {
     var self= this;
     this.state.allEvents && this.state.allEvents.map((event, index) =>{
        if((new Date(event.startDate).getDate() === e) && (new Date(event.startDate).getMonth() === (self.state.activeMonth)))
        {
            dateList.push(e)
        }
        return null;
      })
      return null;
    },this)
    if(dateList){
      var self= this;
      listLI =   list.map(function(e){
          var isIn= dateList.find(_=>_ === e);
        if (isIn) {
          if (e === self.state.activeDay) {
            return <li key={e} value={e} className='d-flex flex-wrap justify-content-center align-items-center booked active' onClick={self.filteEventWithDate}>{e}</li>
          } 
          else {
            return <li key={e} value={e} className='d-flex flex-wrap justify-content-center align-items-center booked' onClick={self.filteEventWithDate}>{e}</li>
          }
        }
        else {
          if (e === self.state.activeDay) {
            return <li key={e} value={e} className='d-flex flex-wrap justify-content-center align-items-center active' onClick={self.filteEventWithDate}>{e}</li>
          }
          else {
            return <li key={e} value={e} className='d-flex flex-wrap justify-content-center align-items-center' onClick={self.filteEventWithDate}>{e}</li>
          }
        }
      },this)
    }
    var settings = {
      dots: true,
      arrows: true,
      infinite: false,
      speed: 300,
      slidesToShow: 4,
      slidesToScroll: 3,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 3,
          }
        },
        {
          breakpoint: 840,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          }
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          }
        },
      ]
    };

    return (
      <div className="web_wrapper">
        <div className="web_main">  
          <div className="web_banner">
            <img src="/web_images/banner.png" alt="banner" />
          </div>
          <div className="web_container">
          <Header history={this.props.history}/>
            <h1>{this.setState.days}</h1>

          </div>
          <div className="month_year_wrapper">
            <div className="web_container">
              <ul className="month_list d-flex flex-wrap justify-content-center">
                {
                  itemMonth
                }
              </ul>
            </div>
            <div className="month_year">
              <ul className="day_list d-flex flex-wrap justify-content-center">
                {listLI}
              </ul>
            </div>
          </div>
          <div className="web_container web_top_filter">
            <div className="web_top_filter_title d-flex align-items-center">
              <h2>Top Events</h2>
              <div className="select_dropdown select_custom">
              <select name="eventTypeFilter" onChange={this.handleFilterChange}>
                  <option value="0" >All</option>
                  <option value="1">Baseball</option>
                  <option value="2">Softball</option>
                </select>
                <span className="angle_down"><i className="fa fa-caret-down" aria-hidden="true"></i></span>
              </div>
              <div className="select_dropdown select_custom">
                <select name="isPresentFilter" onChange={this.handleFilterChange}>
                  <option value="0" >All</option>                  
                  <option value="1">Live</option>
                  <option value="3">Upcoming</option>
                  <option value="2">Past</option>
                </select>
                <span className="angle_down"><i className="fa fa-caret-down" aria-hidden="true"></i></span>
              </div>
            </div>
            
            {
              this.state.topEvents.length === 0 ? <h3 className="noFound" style={{color: '#fff', textAlign: 'center'}}> No Events.</h3>: 
              <div className="web_event_slider">
              <Slider {...settings}> 
                {
                  this.state.topEvents.map((event, index) => <EventBox isShowLogin={this.checkLogin} eventData={event}  onClick={this.eventDetail} history={this.props.history} />)
                }
              </Slider>
              </div>
            }
            
          </div>
          <div className="web_download_section text-center">
            <p>Download our app on your smart device and play more</p>
            <div className="app_links d-flex flex-wrap justify-content-center">
              <NavLink to="/select"><img src="/web_images/playstore_download.svg" alt="play store" /></NavLink>
              <NavLink><img src="/web_images/ios_download.svg" alt="app store" /></NavLink>
            </div>
          </div>
        </div>  
        <div className="web_container_1290">
        <div className="web_eventOuterList">
          {
            this.state.league ? <LeagueListRow history={this.props.history} leagueData={this.state.league} isGotoPayment={false} /> : ''
          }
            
        </div>   
        </div>   
        <div className="web_content web_container">
          <div className="web_content_filter d-flex align-items-center">
            <h2>All Events</h2>
            <div className="select_dropdown select_custom">
            <select name="eventTypeFilter1" onChange={this.handleFilterChangeForAllEvents}>
                  <option value="0" >All</option>
                  <option value="1">Baseball</option>
                  <option value="2">Softball</option>
                </select>
                <span className="angle_down"><i className="fa fa-caret-down" aria-hidden="true"></i></span>
              </div>
              <div className="select_dropdown select_custom">
                <select name="isPresentFilter1" onChange={this.handleFilterChangeForAllEvents}>
                  <option value="0" >All</option>
                  <option value="4">Top</option>
                  <option value="1">Live</option>
                  <option value="3">Upcoming</option>
                  <option value="2">Past</option>
                </select>
                <span className="angle_down"><i className="fa fa-caret-down" aria-hidden="true"></i></span>
            </div>
          </div>
          <div className="web_content_box_wrapper">
          <Row className="mb-4">
            {
              this.state.filterdEvents && this.state.filterdEvents.map((event, index) =>{
                return(
                  <>
                    <Col xl={3} md={4} sm={6} xs={12}>
                      <EventBox isShowLogin={this.checkLogin} history={this.props.history} eventData={event} key={event._id} goForEdit={this.goForEdit} />
                   </Col>
                  </>
                )
              })
            }
          </Row>
          </div>
          {this.state.filterdEvents.length ===0 ? <span className="text_center">No Events.</span>: ""}
        </div>
        <div className="web_content web_container">
          <div className="web_content_filter d-flex align-items-center">
            <h2>Local Events</h2>
            <div className="select_dropdown select_custom">
              <select name="eventTypeFilter2" onChange={this.handleFilterChangeForLocalEvents}>
                  <option value="0" >All</option>
                  <option value="1">Baseball</option>
                  <option value="2">Softball</option>
              </select>
              <span className="angle_down"><i className="fa fa-caret-down" aria-hidden="true"></i></span>
            </div>
            <div className="select_dropdown select_custom">
              <select name="isPresentFilter2" onChange={this.handleFilterChangeForLocalEvents}>
                  <option value="0" >All</option>
                  <option value="1">Live</option>
                  <option value="3">Upcoming</option>
                  <option value="2">Past</option>
              </select>
              <span className="angle_down"><i className="fa fa-caret-down" aria-hidden="true"></i></span>
            </div>
          </div>
          <div className="web_content_box_wrapper">
          <Row className="mb-4">
            {
              this.state.localFilterEvents && this.state.localFilterEvents.map((event, index) =>{
                return(
                  <>
                    <Col xl={3} md={4} sm={6} xs={12}>
                      <EventBox isShowLogin={this.checkLogin} history={this.props.history} eventData={event} key={event._id} goForEdit={this.goForEdit} />
                   </Col>
                  </>
                )
              })
            }
          </Row>
          </div>
         {this.state.localFilterEvents && this.state.localFilterEvents.length ===0 ? <span className="text_center">No Events.</span>: ""}
        </div>

        <div>
          {
            this.state.isShowLogin ? <Login history={this.props.history} class='custom_popup open auth_form m-0' onClose={this.popupClose} /> : ''
          }
        </div>
        <Footer />        
      </div>

    );
  }
}

EventsWeb.propTypes = {
  fetchTopEvents: PropTypes.func.isRequired,
  fetchAllEvents: PropTypes.func.isRequired,
  fetchLocalEvents: PropTypes.func.isRequired,
  fetchLeagueById: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  eventData: state.event,
  leagueData: state.league
});

export default connect(mapStateToProps, { fetchTopEvents, fetchAllEvents, fetchLocalEvents, fetchLeagueById })(EventsWeb);
