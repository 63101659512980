import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { GetFaqs } from '../saga/actions/Common.action'
import Header from "./WebComponents/Header";
import Footer from "./WebComponents/Footer";
import Loader from "react-loader-spinner";


class FAQs extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      faqs: [],
      isLoading:true,
      isMobile:false
    }
  }
  componentDidMount() {
    if(this.props.location.search === "?mobile"){
      this.setState({isMobile: true})
    }
    this.props.GetFaqs();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.faqs && nextProps.faqs.data) {
      this.setState({
        faqs: nextProps.faqs.data,
        isLoading:false
      })
    }
  }


  render() {


    return (
      <div className="web_wrapper">
        <Loader
          className="ab_loader"
          type="Circles"
          color="#02A8A8"
          height={80}
          width={80}
          visible={this.state.isLoading}
        />
        <div className="web_main">
          {
            !this.state.isMobile ?  <div className="web_banner">
            <img src="/web_images/banner.png" alt="banner" />
          </div> :""
          }
         
          <div className="web_container">
          {
             !this.state.isMobile ? <Header history={this.props.history} /> : ""
          }
          </div>
        </div>
        <div className="container_900">
          <div className="text-center faq_title">
            <h1>FAQs</h1>
          </div>
          <div {...{ className: 'fqw_wrap wrapper' }}>
            <ul {...{ className: 'accordion-list' }}>
              {this.state.faqs.map((data, key) => {
                return (
                  <li {...{ className: 'accordion-list__item', key }}>
                    <AccordionItem {...data} />
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
        {
          !this.state.isMobile ?  <Footer /> : ""
        }
      </div>

    );
  }
}

FAQs.propTypes = {
  GetFaqs: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  faqs: state.common.faqs,
});

export default connect(mapStateToProps, { GetFaqs })(FAQs);

class AccordionItem extends React.Component {
  state = {
    opened: false
  }

  render() {
    const {
      props: {
        ans,
        que
      },
      state: {
        opened
      }
    } = this

    return (
      <div
        {...{
          className: `accordion-item, ${opened && 'accordion-item--opened'}`,
          onClick: () => { this.setState({ opened: !opened }) }
        }}
      >
        <div {...{ className: 'accordion-item__line' }}>
          <h3 {...{ className: 'accordion-item__title' }}>
            {que}
          </h3>
        </div>
        <div {...{ className: 'accordion-item__inner' }}>
          <div {...{ className: 'accordion-item__content' }}>
            <p {...{ className: 'accordion-item__paragraph' }}>
              {ans}
            </p>
          </div>
        </div>
      </div>
    )
  }
}
