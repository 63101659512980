import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Card,
  CardGroup,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from "reactstrap";
import { login } from "../../saga/actions/Authentication/Login.action";
import { getUserInfo } from "../../saga/actions/Authentication/UpdateProfile.action";
import SimpleReactValidator from "simple-react-validator";
import Loader from "react-loader-spinner";
import { ToastContainer, toast } from 'react-toastify';

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      class: "custom_popup auth_form m-0",
      isLoading: false,
    };
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
  }

  componentDidMount() {
    this.setState({
      class: this.props.class,
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.loginData) {
      if (nextProps.loginData.isLoginSuccess) {
        nextProps.loginData.isLoginSuccess = false;
        this.props.getUserInfo();
        this.setState({ isLoading: false });
        this.closePopup();
      }

      if (nextProps.loginData.loginError) {
        this.setState({ isLoading: false });
        toast.error(nextProps.loginData.loginError, {
          position: toast.POSITION.TOP_RIGHT
        });
        nextProps.loginData.loginError = "";
      }

      if (nextProps.loginData.loginLoader) {
        this.setState({ isLoading: true });
      }
    }
  }

  handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };

  closePopup = () => {
    this.setState({
      email: "",
      password: "",
      class: "custom_popup auth_form m-0",
    });
    this.props.onClose();
    this.props.history.push({pathname: '/'})
  };

  login = (e) => {
    e.preventDefault();
    if (this.validator.allValid()) {
      const user = {
        username: this.state.email,
        password: this.state.password,
      };
      this.props.login(user);
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  render() {
    return (
      <Row className={this.state.class}>
        <Loader
          className="ab_loader"
          type="Circles"
          color="#02A8A8"
          height={80}
          width={80}
          visible={this.state.isLoading}
        />
        <div className="popup_pad popup_600 p-0">
          <CardGroup>
            <Card className="p-0 m-0">
              <div className="inner_popup">
                <span className="close_popup" onClick={this.closePopup}>
                  <img src="/web_images/close.svg" alt="close"/>
                </span>
                <Form>
                  <h1 className="mb-3">Login</h1>

                  <div className="mb-4">
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText className="justify-content-center" style={{"width":"40px","fontSize":"16px"}}>
                        <i className="fa fa-user-o"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      type="text"
                      name="email"
                      placeholder="Email or Number"
                      value={this.state.email}
                      style={{"height":"35px","fontSize":"16px"}}
                      onChange={this.handleChange}
                    />
                  </InputGroup>
                  <span style={{ color: "red" }}>
                    {this.validator.message(
                      "Email or Number",
                      this.state.email,
                      "required"
                    )}
                  </span>
                  </div>

                  <div className="mb-4">
                  <InputGroup >
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText className="justify-content-center" style={{"width":"40px","fontSize":"16px"}}>
                        <i className="fa fa-unlock-alt"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      type="password"
                      name="password"
                      placeholder="Password"
                      value={this.state.password}
                      style={{"height":"35px","fontSize":"16px"}}
                      
                      onChange={this.handleChange}
                    />
                  </InputGroup>
                  <span style={{ color: "red" }}>
                    {this.validator.message(
                      "Password",
                      this.state.password,
                      "required"
                    )}
                  </span>
                  </div>

                  <div>
                    <button className="px-4 btn_bg mr-4" onClick={this.login}>
                      {" "}
                      Login{" "}
                    </button>

                    <button className="px-4 btn_bg" onClick={this.closePopup}>
                      {" "}
                      Cancel{" "}
                    </button>
                  </div>
                </Form>
              </div>
            </Card>
          </CardGroup>
        </div>
        <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={2000} />
      </Row>
    );
  }
}

Login.propTypes = {
  login: PropTypes.func.isRequired,
  getUserInfo: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  loginData: state.login,
});

export default connect(mapStateToProps, { login, getUserInfo })(Login);
