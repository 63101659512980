import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Header from "../Header";
import LeagueListRow from "../League/LeagueListRow";
import { fetchLeagueById, joinLeague, sendNonceToServer, leagueComplete } from '../../../saga/actions/League.action';
import { uploadWaivers, uploadInsurance, deleteImage } from '../../../saga/actions/Common.action';
import { Row, Col, TabContent, TabPane, Nav, NavItem, NavLink, Form, Label, FormGroup, Input } from 'reactstrap';
import DataTable from "../DataTable";
import classnames from 'classnames';
import Loader from 'react-loader-spinner';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SimpleReactValidator from 'simple-react-validator';
import LeaguePayment from './LeaguePayment';
import * as XLSX from 'xlsx';

class LeagueJoin extends Component {

  constructor(props) {
    super(props);
    this.state = {
      insuranceLoader: false,
      waiversLoader: false,
      showPaymentButton:false,
      paymentObj:undefined,
      paymentType:0,
      couponError:"",
      paymentTypeError:"",
      leagueJoin: {
        isManager: true,
        managerName: '',
        managerEmail: '',
        managerPhone: "",
        waiversImg: '',
        insuranceImg: '',
        rosters: [],
        paymentType: '',
        couponCode: '',
        metaPayment: {},
        paymentMethod: '',
        amount: 0,
      },
      roster: {
        fname: '',
        lname: '',
        phone: '',
        email: '',
        number: '',
        position: '',
        dob: new Date(),
        country_code: ''
      },
      isShowPayment: true,
      league: [],
      currentActive: 1,
      activeTab: '1',
      file: null,
      fileName: null,
      AddRoster: false,
      birthDate: new Date(),
      DeletePlayer: false,
      deletePlayerId: ''
    }
    this.goBackToLeagueDetail = this.goBackToLeagueDetail.bind(this);
    this.renderSwitch = this.renderSwitch.bind(this);
    this.deleteRosterPlayer = this.deleteRosterPlayer.bind(this);
    this.payment = this.payment.bind(this);
    this.updatePlayer = this.updatePlayer.bind(this);
    this.deletePlayer = this.deletePlayer.bind(this);
    this.savePlayer = this.savePlayer.bind(this);
    this.handleQA = this.handleQA.bind(this);
    this.toggle = this.toggle.bind(this);
    this.validator = new SimpleReactValidator({autoForceUpdate: this});
    this.formValidator = new SimpleReactValidator({autoForceUpdate: this});
  }

  componentDidMount() {
    this.props.fetchLeagueById();
  }
  componentWillUnmount(){
    localStorage.removeItem('playerList');
  }
  componentWillReceiveProps(nextProps)
  {
    if(nextProps.leagueData.league && nextProps.leagueData.isLeagueLoaded)
    {
      nextProps.leagueData.isLeagueLoaded = false
      var leagueData= nextProps.leagueData.league;  

      // if(leagueData.selPer === 4)
      //   this.props.history.push("/")

      this.setState({
        league: leagueData,
      })

      if(nextProps.leagueData.league && nextProps.leagueData.league.questions)
      {

        this.setState({
          leagueJoin: {
            ...this.state.leagueJoin,
            question: nextProps.leagueData.league.questions
          }
        })
      }

      if(leagueData.payment.paymentType.length === 1 && leagueData.payment.paymentType.indexOf(2) !== -1)
      {
        this.setState({
          showPaymentButton:true,
          isShowPayment: false,
          currentActive: 1
        })
      }
    }

    if(nextProps.commonData.waivers && nextProps.commonData.isWaiversUploaded)
    {
      nextProps.commonData.isWaiversUploaded = false
      this.setState({
        waiversLoader: false,
        leagueJoin: {
          ...this.state.leagueJoin,
          waiversImg: nextProps.commonData.waivers
        }
      })
    }

    if(nextProps.commonData.insurance && nextProps.commonData.isInsuranceUploaded)
    {
      nextProps.commonData.isInsuranceUploaded = false
      this.setState({
        insuranceLoader: false,
        leagueJoin: {
          ...this.state.leagueJoin,
          insuranceImg: nextProps.commonData.insurance
        }
      })
    }

    if (nextProps.commonData.isDeleted) {
      nextProps.commonData.isDeleted = false
    }
    if(nextProps.leagueData.isJoined)
    {
      nextProps.leagueData.isJoined = false
      this.props.history.push("/WebApp/league/joined")
    }
    if(nextProps.leagueData.isComplete)
    {
      nextProps.leagueData.isComplete = false
      this.props.history.push("/WebApp/league/joined")
    }


    if(nextProps.leagueData.isJoinedError)
    {
      var Error = nextProps.leagueData.isJoinedError
      toast.error(Error, {
        position: toast.POSITION.TOP_RIGHT
      });
      nextProps.leagueData.isJoinedError = ''
    }
    if(nextProps.leagueData.isCompleteError)
    {
      var Errors = nextProps.leagueData.isCompleteError
      toast.error(Errors, {
        position: toast.POSITION.TOP_RIGHT
      });
      nextProps.leagueData.isCompleteError = ''
    }
    if(nextProps.leagueData.isSended)
    {
      nextProps.leagueData.isSended = false
      this.setState({payment:nextProps.leagueData.paymentObject.cardDetail})
      //this.venmoPaymentSuccessful(nextProps.leagueData.paymentObject.cardDetail)
    }
  }

  submitRoster = (e) => {
    e.preventDefault();

    if (this.formValidator.allValid())
    {

      var roster = this.state.leagueJoin
      roster.league = this.state.league._id
  
      var rosters= JSON.parse(localStorage.getItem('playerList'))
      if(rosters)
      {
        rosters.forEach(roster => {
             delete roster["id"]
        });
        roster.rosters = rosters
      }

      this.props.joinLeague(roster)

    }
    else
    {
      this.formValidator.showMessages();
      this.forceUpdate();
    }
  }

  stripePaymentSuccessful = (e) => {
    e.preventDefault()
    const payment=this.state.paymentObj
    var roster = this.state.leagueJoin
    roster.league = this.state.league._id
    roster.metaPayment = payment
    roster.event= this.state.league._id
    roster.paymentType = this.state.paymentType

    var rosters= JSON.parse(localStorage.getItem('playerList'))
    if(rosters)
    {
      rosters.forEach(roster => {
           delete roster["id"]
      });
      roster.rosters = rosters
    }
      if(this.state.paymentType === 0){
        this.setState({paymentTypeError:"Please select payment type."})
      }
    else{
      this.setState({paymentTypeError:"",couponError:""})
      //this.props.joinLeague(roster)
      this.props.leagueComplete(roster)
    }
  }

  venmoPaymentSuccessful = (e) => {
    e.preventDefault()
    const payment=this.state.paymentObj
    var roster = this.state.leagueJoin
    roster.league = this.state.league._id
    roster.metaPayment = payment

    var rosters= JSON.parse(localStorage.getItem('playerList'))
    if(rosters)
    {
      rosters.forEach(roster => {
           delete roster["id"]
      });
      roster.rosters = rosters
    }

   // this.props.joinLeague(roster)
    this.props.joinLeague(roster)
  }

  sendNanceTokenToServer = (nanceToken) => {
    this.setState({showPaymentButton:true})
    var tokenObject = {
      amount: this.state.leagueJoin.amount,
      payment_method_nonce: nanceToken,
      deviceDataFromTheClient: {}
    }
    this.props.sendNonceToServer(tokenObject)
  }

  showPaymentButton = (payment) => {
    this.setState({
      showPaymentButton: true,
      paymentObj:payment
    })
  }

  handlePaymentPage = (name, value) => {
    if(name==="paymentType"){
      this.setState({
        paymentType:value,
        paymentTypeError:""
      })
    }

    if(name==="paymentType" && value === 2){
      this.setState({
        showPaymentButton: true,
      })
    }

    if(name==="paymentType" && ((value === 1) || (value === 3)))
    {
      this.setState({
        showPaymentButton: false,
      })
    }

    if(name === "couponCode"){
      this.setState({couponError:""})
    }
    this.setState({
      leagueJoin: {
        ...this.state.leagueJoin,
        [name] : value
      }
    })
  }


  handleRosterForm = (e) => {
    e.preventDefault();

    const {name, value} = e.target;
    if(!e.target.validity.valid)
    {
       return null
    }
    if(!e.target.validity.valid)
    {
       return null
    }
    this.setState({
      leagueJoin: {
        ...this.state.leagueJoin,
        [name]: name === 'isManager' ?  JSON.parse(value) : value
      }
    })


  }

  toggle(tab) {

    if (tab !== 1 && tab !== 2)
    {
      tab.preventDefault();
      if(this.state.currentActive === 1)
        tab = 2;
      else
        tab = 1;
    }

    if (this.state.currentActive !== tab) {

      if(tab === 2)
      {
        if(this.state.leagueJoin.isManager)
        {
          if (this.formValidator.allValid())
          {
            this.setState({ currentActive: tab });
          }
          else
          {
            this.formValidator.showMessages();
            this.forceUpdate();
          }
        }
        else
        {
          this.setState({ currentActive: tab });
        }
      }
      else
        this.setState({ currentActive: tab });
    }
  }

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
        this.setState({ activeTab: tab });
    }
  }

  uploadFile = (e) => {
    e.preventDefault();
    var self = this;
    var files = e.target.files, f = files[0];
    var reader = new FileReader();
    reader.onload = function (e) {

        var data = e.target.result;
        let readedData = XLSX.read(data, {type: 'binary'});
        const wsname = readedData.SheetNames[0];
        const ws = readedData.Sheets[wsname];
        const dataParse = XLSX.utils.sheet_to_json(ws, {header:1});

        var rosters= []

        dataParse.forEach(data => {
          var roster = {}
            roster.id = Math.random();
            roster.fname = data[0]
            roster.lname = data[1]
            roster.phone = data[2]
            roster.email = data[3]
            roster.number = data[4]
            roster.position = data[5]
            roster.dob = data[6]
            roster.country_code = data[7]

            rosters.push(roster)
        });

        var playerList = JSON.parse(localStorage.getItem('playerList'))
        if(playerList === null)
          playerList = []

          rosters.forEach(roster => {
            playerList.push(roster)
          });
        
        localStorage.setItem('playerList', JSON.stringify(playerList))
        self.setState({
          activeTab: '2'
        })
    };
    reader.readAsBinaryString(f)
  }

  uploadWaiversFile = (event) => {
    var imageData = {
      documentType: 20,
      data: event.target.files[0]
    }
    this.setState({
      waiversLoader: true
    })
    this.props.uploadWaivers(imageData)
  }

  uploadInsuranceFile = (event) => {
    var imageData = {
      documentType: 20,
      data: event.target.files[0]
    }
    this.setState({
      insuranceLoader: true
    })
    this.props.uploadInsurance(imageData)
  }

  startDateSelect = (date) => {
   // date.preventDefault();
    this.setState({
      roster : {
        ...this.state.roster,
        dob : date
      }
    })
  }

  removeFile = (imageId, imageOf) => {
    var self = this;
    if(imageOf.target.id === 'insurance')
    {
      setTimeout(() => {
        self.setState({
            leagueJoin: {
              ...this.state.leagueJoin,
              insuranceImg: ''
            }
        },
        function () {
          self.setState({
            leagueJoin: {
              ...this.state.leagueJoin,
              insuranceImg: ''
            }
          })
        });
      }, 10)
    }
    else
    {
      setTimeout(() => {
        self.setState({
            leagueJoin: {
              ...this.state.leagueJoin,
              waiversImg: ''
            }
        },
        function () {
          self.setState({
            leagueJoin: {
              ...this.state.leagueJoin,
              waiversImg: ''
            }
          })
        });
      }, 10)
    }
    self.props.deleteImage(imageId._id)
  }

  AddRoster = () => {
    this.setState({
      AddRoster: !this.state.AddRoster
    })
  }

  closePopup = () => {
    this.setState({
      AddRoster: false,
      DeletePlayer: false
    })
  }

  goBackToLeagueDetail = () => {
    this.props.history.push("/WebApp/league")
  }

  deletePlayer(playerId, e) {
    e.preventDefault();
    this.setState({
      deletePlayerId: playerId,
      DeletePlayer: !this.state.DeletePlayer
    })
  }

  payment = (e) => {
    e.preventDefault();
    this.props.history.push("/WebApp/league/joined")
  }

  handleQA(e) {
    e.preventDefault();    
    const {name, value} = e.target;

    var questions = this.state.leagueJoin.question

    questions.forEach(question => {
      if(question._id === name)
        question.answer = value
    });

    this.setState({
      leagueJoin: {
        ...this.state.leagueJoin,
        question: questions
      }
    })



  }

  generateQuestionBox = () => {
    var self = this;
    return this.state.league && this.state.league.questions && this.state.league.questions.map(function (question, index) {
      return (
        <div key={index} className="create_event_row">
          <FormGroup>
            <Label>{question.question}</Label>
            <input type="text" name={question._id} value={question.answer} placeholder="Enter your answer." onChange={self.handleQA}/>
          </FormGroup>
        </div>
      )
      })
  }


  renderSwitch(data) {

    const { isManager } = this.state.leagueJoin;
    var rostersData= JSON.parse(localStorage.getItem('playerList'));
    var roasterRequired= this.state.league && this.state.league.settings && this.state.league.settings.haveTeamRoster
    var haveWaiverRequired = this.state.league && this.state.league.settings && this.state.league.settings.haveWaiver
    var insuranceRequired =this.state.league && this.state.league.settings && this.state.league.settings.isShowInsurance
  
    switch (this.state.currentActive) {
      case 1:
        return (
          <div className="bg_white web_description_box">
            <div className="web_join_title text-center">
              <h2>Join the League</h2>
            </div>
            <Row>
              <Col lg={8} className="web_event_join">

                {

                  this.state.league && this.state.league.settings && this.state.league.settings.haveTeamRoster === false ? '' :
                
                <>
                <div className="create_event_row ">
                  <label>Upload Roster</label>
                </div>
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: this.state.activeTab === '1' })}
                      onClick={() => { this.toggleTab('1'); }}
                    >
                      Import
                        </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: this.state.activeTab === '2' })}
                      onClick={() => { this.toggleTab('2'); }}
                    >
                      Manually
                        </NavLink>
                  </NavItem>
                </Nav>
                <div className="create_event_row">
                  <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId="1" className="p-0">
                      <Row>
                        <Col lg="12" sm="12" md="12">
                          <div className="create_event_row">
                            <div className="create_event_fileUpload file_input">
                              <i className="fa fa-cloud-upload" aria-hidden="true"></i>
                              <p>Click or drop image</p>
                              <div className="image_upload_wrap">
                                <img src={this.state.file} alt='' />
                              </div>
                              <input name="logofile" id="" type="file" className="img_file_input form-control-file" onChange={this.uploadFile} />
                            </div>
                            <div className="image_prev_name" style={{display:this.state.fileName ? "block": "none"}}>
                              <p className="image_name">{this.state.fileName}</p>
                              <span className="rounded-circle round_close d-flex align-items-center justify-content-center"><img src="/web_images/close.svg" alt="close"/></span>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="2" className="p-0">
                      <Row>
                        <Col lg="12" sm="12" md="12" >
                          <div className="create_event_row web_manual_Tbl">
                            <DataTable  data={data} isShowSearch={false}/>
                            <span className="btn_green add_roster" onClick={this.AddRoster}>Add New Roster</span>
                          </div>
                        </Col>
                      </Row>
                    </TabPane>
                  </TabContent>
                </div>
                <span style={{color : 'red'}}>{this.formValidator.message('Roaster',rostersData, roasterRequired? "required" : "")}</span>

                </>
                }

                {
                  this.state.league && this.state.league.settings && this.state.league.settings.haveWaiver === false ? '' :

                  <div className="create_event_row">
                    <Label for="exampleCity">Upload Waivers</Label>
                    <div className="create_event_fileUpload file_input">
                    <Loader className="ab_loader_ab" type="Circles" color="#02A8A8" height={50} width={50} visible={this.state.waiversLoader} />
                    {
                      this.state.waiversLoader ? '' :
                      <>
                        <i className="fa fa-cloud-upload" aria-hidden="true"></i>
                        <p>Click or drop image</p>
                      </>
                    }
                      <div className="image_upload_wrap">
                        <img src={this.state.leagueJoin.waiversImg.filePath} alt='' />
                      </div>
                      <input name="logofile" id="waivers" type="file" className="img_file_input form-control-file" onChange={this.uploadWaiversFile} />
                    </div>
                    <div className="image_prev_name" style={{display:this.state.leagueJoin.waiversImg.originalname ? "block": "none"}}>
                      <p className="image_name">{this.state.leagueJoin.waiversImg.originalname}</p>
                      <span className="rounded-circle round_close d-flex align-items-center justify-content-center" onClick={this.removeFile.bind(this, this.state.leagueJoin.waiversImg)}><i className="fa fa-times" id="waivers" aria-hidden="true"></i></span>
                    </div>
                    {/* <span style={{color : 'red'}}>{this.formValidator.message('Waiver Image',this.state.leagueJoin.waiversImg.filePath, this.state.league && this.state.league.settings && this.state.league.settings.haveWaiver === true ? 'required' : '')}</span> */}
                    <span style={{color : 'red'}}>{this.formValidator.message('Waivers',this.state.leagueJoin.waiversImg, haveWaiverRequired ? "required" : "")}</span>
                 
                  </div>
                }

                {
                  this.state.league && this.state.league.settings && this.state.league.settings.isShowInsurance === false ? '' :

                  <div className="create_event_row">
                    <Label for="exampleCity">Upload Insurance </Label>
                    <div className="create_event_fileUpload file_input">
                    <Loader className="ab_loader_ab" type="Circles" color="#02A8A8" height={50} width={50} visible={this.state.insuranceLoader} />
                    {
                      this.state.insuranceLoader ? '' :
                      <>
                        <i className="fa fa-cloud-upload" aria-hidden="true"></i>
                        <p>Click or drop image</p>
                      </>
                    }

                      <div className="image_upload_wrap banner_upoad_img">
                        <img src={this.state.leagueJoin.insuranceImg.filePath} alt='' />
                      </div>
                      <input name="logofile" id="insurance" type="file" className="img_file_input form-control-file" onChange={this.uploadInsuranceFile} />
                    </div>
                    <div className="image_prev_name" style={{display:this.state.leagueJoin.insuranceImg.originalname ? "block": "none"}}>
                      <p className="image_name">{this.state.leagueJoin.insuranceImg.originalname}</p>
                      <span className="rounded-circle round_close d-flex align-items-center justify-content-center" onClick={this.removeFile.bind(this, this.state.leagueJoin.insuranceImg)}><i className="fa fa-times" id="insurance" aria-hidden="true"></i></span>
                    </div>
                    {/* <span style={{color : 'red'}}>{this.formValidator.message('Insurance Image',this.state.leagueJoin.insuranceImg.filePath, (this.state.league) && (this.state.league.settings) && (this.state.league.settings.isShowInsurance) === false ? '' : 'required')}</span> */}
                    <span style={{color : 'red'}}>{this.formValidator.message('insurance',this.state.leagueJoin.insuranceImg, insuranceRequired? "required" : "" )}</span>
                 
                  </div>
                }



                <div className="create_event_row create_event_type">
                  <label>Do You Have Team Manager?</label>

                  <Row className="">
                    <Col md={6} sm={6} xs={6}>
                      <button className={isManager === true ? 'text-center button_type active' : 'text-center button_type' } name="isManager" value={true} onClick={this.handleRosterForm}>
                      Yes
                      </button>
                    </Col>
                    <Col md={6} sm={6} xs={6}>
                      <button className={isManager === false ? 'text-center button_type active' : 'text-center button_type' } name="isManager" value={false} onClick={this.handleRosterForm}>
                      No
                      </button>
                    </Col>
                  </Row>
                </div>
                {
                  isManager === true ? 
                  <>
                    <div className="create_event_row">
                      <FormGroup>
                        <Label>Manager</Label>
                        <input type="text" name="managerName" placeholder="Enter manager name" value ={this.state.leagueJoin.managerName} onChange={this.handleRosterForm} />
                        <span style={{color : 'red'}}>{this.formValidator.message('Name',this.state.leagueJoin.managerName, isManager === true ? 'required' : '')}</span>
                      </FormGroup>
                    </div>

                    <div className="create_event_row">
                      <FormGroup>
                        <Label>Manager Email</Label>
                        <input type="text" name="managerEmail" value={this.state.leagueJoin.managerEmail} placeholder="Enter manager email" onChange={this.handleRosterForm} />
                        <span style={{color : 'red'}}>{this.formValidator.message('Email',this.state.leagueJoin.managerEmail, isManager === true ? 'required|email' : '')}</span>
                      </FormGroup>
                    </div>

                    <div className="create_event_row">
                      <FormGroup>
                        <Label>Manager Phone</Label>
                        <input type="text" name="managerPhone" placeholder="Enter manager phone" value={this.state.leagueJoin.managerPhone} onChange={this.handleRosterForm} pattern="[0-9]*"/>
                        <span style={{color : 'red'}}>{this.formValidator.message('Phone',this.state.leagueJoin.managerPhone, isManager === true ? 'required|numeric' : '')}</span>
                      </FormGroup>
                    </div>
                  </> : ''
                }
                
             

                <div className="create_event_row">
                  {this.generateQuestionBox()}
                </div>


                {/* {
                  !this.state.isShowPayment ?
                  <div className="create_event_row">
                  <FormGroup>
                    <button className="btn_green" onClick={this.submitRoster} >Submit</button>                  
                  </FormGroup>
                </div> : ''
                } */}
                

              </Col>
            </Row>
          </div >
        );
      case 2:
        return (
          <LeaguePayment 
            handlePayment={this.handlePayment} 
            leagueData={this.state.league} 
           // stripePaymentSuccessful={this.stripePaymentSuccessful} 
            sendNanceTokenToServer={this.sendNanceTokenToServer}
            paymentTypeError={this.state.paymentTypeError}
            handlePaymentPage={this.handlePaymentPage}
            showPaymentButton={this.showPaymentButton}
          />
        );

      default: { }
    }
  }

  handleRosterInput = (e) => {
    e.preventDefault();
    const {value, name} = e.target;

    if(!e.target.validity.valid)
    {
        return null
    }

    this.setState({
      roster: {
        ...this.state.roster,
        [name] : value

      }
    })
  }


  clearRoster = () => {
    this.setState({
      AddRoster: false,
      roster: {
        ...this.state.roster,
        id: '',
        fname: '',
        lname: '',
        phone: '',
        email: '',
        number: '',
        position: '',
        dob: new Date(),
        country_code: ''
      },
    })
  }

  savePlayer(e) {
    e.preventDefault();

    if (this.validator.allValid())
    {

      var newRoster = this.state.roster
      var playerList = JSON.parse(localStorage.getItem('playerList'))
      if(playerList === null)
        playerList = []
  
      if(newRoster.id)
      {
        playerList.forEach(player => {
            if(player.id === newRoster.id)
            {
              player.fname = newRoster.fname
              player.lname = newRoster.lname
              player.phone = newRoster.phone
              player.email = newRoster.email
              player.number = newRoster.number
              player.position = newRoster.position
              player.dob = newRoster.dob
              player.country_code = newRoster.country_code
            }
        });
      }
      else
      {
        newRoster.id = Math.random();
        playerList.push(newRoster)
      }
      localStorage.setItem('playerList', JSON.stringify(playerList))
      this.clearRoster();
    }
    else
    {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }

  deleteRosterPlayer() {
    var playerId = this.state.deletePlayerId
    var playerList = JSON.parse(localStorage.getItem('playerList'))
    playerList = playerList.filter(_ => _.id !== playerId)
    localStorage.setItem('playerList', JSON.stringify(playerList))
    this.setState({ DeletePlayer: false })
  }

  updatePlayer(player, e) {
    e.preventDefault();
    this.setState({
      roster: player,
      AddRoster: true
    })
  }

  render() {
    const { currentActive } = this.state;
    const league = this.state.league;
    const roster = this.state.roster;
    const showPayament= this.state.showPaymentButton
    var playerList = JSON.parse(localStorage.getItem('playerList')) 

    var rows = []

    playerList && playerList.map((roster, index) => rows.push({
      'no' : index + 1 +'.',
      'name' : roster.fname + ' ' + roster.lname,
      'action' : <><button className="btn bg_blue_btn mr-2" onClick={this.updatePlayer.bind(this, roster)}>Edit</button><button className="btn light_red_btn mr-2" color="danger" onClick={this.deletePlayer.bind(this, roster.id)} >Delete</button></>,
    }))

    const data = {
      columns: [
      {
        label: 'No.',
        field: 'no',
        width: 150
      },  
      {
        label: 'Name',
        field: 'name',
        width: 270
      },
      {
        label: 'Actions',
        field: 'action',
        width: 100
      }
      ],
      rows: rows
    };

    return (
      <div className="web_wrapper">
        <div className="web_main inner_pages">
          <div className="web_banner">
            <img src="/web_images/banner.png" alt="banner" />
          </div>
          <div className="web_container">
          <Header history={this.props.history}/>
          </div>
        </div>
        <div className="web_container_1290">
          <div className="web_eventOuterList">
            {
              league ? <LeagueListRow history={this.props.history} isHideJoinButton={true} leagueData={league} /> : ''
            }
          </div>
          <div className="container_900">
            <Form>
              <div className="d-flex flex-wrap justify-content-between mb-4 mt-4">
              <div className="number_step">
                  <ul className="mb-0">
                    <li className={currentActive >= 1 ? "active" : ""} onClick={this.toggle.bind(this, 1)}><span>1</span></li>
                    <li className={currentActive >= 2 ? "active" : ""} onClick={this.toggle.bind(this, 2)}><span>2</span></li>
                  </ul>
                </div>
                <div className="top_right_buttons">
                  <button className="btn_green mr-4" onClick={this.toggle} >{currentActive === 1 ? 'Next' : 'Previous'}</button>
                  {showPayament && currentActive === 2 ?  <button className="btn_green" onClick={this.stripePaymentSuccessful} >Payment</button> : ''}
                </div>
              </div>
              {this.renderSwitch(data)}

              <div className="d-flex flex-wrap justify-content-between mb-4 mt-4">
              <div className="number_step">
                  <ul className="mb-0">
                    <li className={currentActive >= 1 ? "active" : ""} onClick={this.toggle.bind(this, 1)}><span>1</span></li>
                    <li className={currentActive >= 2 ? "active" : ""} onClick={this.toggle.bind(this, 2)}><span>2</span></li>
                  </ul>
                </div>
                <div className="top_right_buttons">
                  <button className="btn_green mr-4" onClick={this.toggle.bind(this, currentActive === 1 ? 2 : 1)} >{currentActive === 1 ? 'Next' : 'Previous'}</button>
                  {showPayament && currentActive === 2 ?  <button className="btn_green" onClick={this.stripePaymentSuccessful} >Payment</button> : ''}
                </div>
              </div>

            </Form>
          </div>
        </div>
        {/* Add Roster Popup */}
        <div className={`custom_popup ${this.state.AddRoster ? 'open' : ''}`}>
          <div className="popup_pad popup_710">
            <div className="inner_popup">
              <span className="close_popup" onClick={this.closePopup}><img src="/web_images/close.svg" alt="close"/></span>
              <h1>Edit Roster</h1>
              <Form>
                <div className="create_event_row p-0">
                  <Label>Name</Label>
                  <Row>
                    <Col className="mb-4 ">
                      <FormGroup>
                        <Input type="text" name="fname" value={roster.fname} id="" placeholder="First Name" onChange={this.handleRosterInput} />
                        <span style={{color : 'red'}}>{this.validator.message('First name',roster.fname, 'required')}</span>
                      </FormGroup>
                    </Col>
                    <Col className="mb-4 ">
                      <FormGroup>
                        <Input type="text" name="lname"  value={roster.lname} id="" placeholder="Last Name" onChange={this.handleRosterInput} />
                        <span style={{color : 'red'}}>{this.validator.message('Last name',roster.lname, 'required')}</span>
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
                <div className="create_event_row p-0">
                  <Row>
                    <Col sm={4} xs={6} className="mb-4 ">
                      <Label>Number</Label>
                      <FormGroup>
                        <Input pattern="[0-9]*" name="number" value={roster.number} id="" placeholder="Number" onChange={this.handleRosterInput} />
                        <span style={{color : 'red'}}>{this.validator.message('Number',roster.number, 'required|numeric')}</span>
                      </FormGroup>
                    </Col>
                    <Col sm={4} xs={6} className="mb-4 ">
                      <Label>Position</Label>
                      <FormGroup>
                        {/* <Input type="text" name="position" value={roster.position} id="" placeholder="Player" onChange={this.handleRosterInput} /> */}
                        <div className="select_custom">
                          <select className="position" name="position" onChange={this.handleRosterInput} value={roster.position}>
                            <option value="Outfield-Left">Outfield-Left</option>
                            <option value="Outfield-Right">Outfield-Right</option>
                            <option value="Outfield-Center">Outfield-Center</option>
                            <option value="Infield-Third">Infield-Third</option>
                            <option value="Infield-Short">Infield-Short</option>
                            <option value="Infield-Second">Infield-Second</option>
                            <option value="Infield-First">Infield-First</option>
                            <option value="Catcher">Catcher</option>
                            <option value="Pitcher">Pitcher</option>
                          </select>
                          <span className="angle_down"><i className="fa fa-angle-down" aria-hidden="true"></i></span>
                        </div>
                        <span style={{color : 'red'}}>{this.validator.message('Position',roster.position, 'required')}</span>
                      </FormGroup>
                    </Col>
                    <Col sm={4} xs={12} className="mb-4 ">
                      <Label>Birthdate</Label>
                      <FormGroup>
                        <DatePicker
                          selected={new Date(roster.dob)}
                          showYearDropdown={true}
                          maxDate={new Date()}
                          onChange={this.startDateSelect}
                          name="DateOfBirth"
                        />
                        <span className="date_icon"><img src="/web_images/date_icon.svg" alt="start date" /></span>
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
                <div className="create_event_row ">
                  <Row>
                    <Col sm={6} xs={12} className="mb-4 ">
                      <Label>Phone Number</Label>
                      <FormGroup>
                        <Input pattern="[0-9]*" name="phone" value={roster.phone} id="" className="country_code_input" placeholder="Phone" onChange={this.handleRosterInput} />
                        <select className="country_code" name="country_code" onChange={this.handleRosterInput} value={roster.country_code}>
                          <option value="+1">+1</option>
                          <option value="+2">+2</option>
                          <option value="+3">+3</option>
                          <option value="+4">+4</option>
                          <option value="+91">+91</option>
                        </select>
                        <span style={{color : 'red'}}>{this.validator.message('Phone',roster.phone, 'required|numeric')}</span>
                      </FormGroup>
                    </Col>
                    <Col sm={6} xs={12} className="mb-4 ">
                      <label>Email Address</label>
                      <FormGroup>
                        <Input type="text" value={roster.email} name="email" id="" placeholder="Email" onChange={this.handleRosterInput} />
                        <span style={{color : 'red'}}>{this.validator.message('Email',roster.email, 'required')}</span>
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
                <div className="text-center">
                  <button className="btn btn_green" onClick={this.savePlayer}>Save</button>
                </div>
              </Form>
            </div>
          </div>
        </div>

        {/* Add Roster Popup */}
        <div className={`custom_popup ${this.state.DeletePlayer ? 'open' : ''}`}>
          <div className="popup_pad popup_510 p-0">
            <div className="inner_popup text-center">
              <span className="close_popup" onClick={this.closePopup}><img src="/web_images/close.svg" alt="close"/></span>
              <div className="delete_player_container">
                <h2 className="error">Delete Player ?</h2>
                <p>Are You sure !! Do you want to delete this player.</p>
              </div>
            </div>
            <div className="deletes_buttons">
              <button onClick={this.deleteRosterPlayer} className="yes">Yes</button>
              <button onClick={this.closePopup}>No</button>
            </div>
          </div>
        </div>

        <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={2000} />
      </div>
    );
  }
}


LeagueJoin.propTypes = {
  fetchLeagueById: PropTypes.func.isRequired,
  joinLeague: PropTypes.func.isRequired,
  deleteImage: PropTypes.func.isRequired,
  uploadWaivers: PropTypes.func.isRequired,
  uploadInsurance: PropTypes.func.isRequired,
  sendNonceToServer: PropTypes.func.isRequired,
  leagueComplete : PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  leagueData: state.league,
  commonData: state.common
});

export default connect(mapStateToProps, { sendNonceToServer,leagueComplete, deleteImage, fetchLeagueById, joinLeague, uploadWaivers, uploadInsurance })(LeagueJoin);