import React from "react";
import { Col } from "reactstrap";
class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.gotoLink = this.gotoLink.bind(this);
  }

  gotoLink(link, e) {
    window.open(link, "_blank");
  }

  render() {
    const hotel = this.props.hotelData;
    return (
      <Col sm={6} className="">
        <div className="web_hotel_venue">
          <div className="venue_image">
            <img
              src="https://artoon-taxuz.s3.amazonaws.com/7/b7c3fd18ba90aec6eb05d11e6c942743.jpg"
              alt="venue"
              className="rounded"
            />
            <div className="hotel_title">
              <h2>{hotel.name}</h2>
              <p>{hotel.hotelAddress}</p>
            </div>
          </div>
          {/* <div className="venue_desc">
        </div> */}

          {/* <div className="rating_row">
            <div className="d-flex align-items-center mb-2">
              <span className="rating_btn rounded">{hotel.hotelRating}.0</span>
              <p>({hotel.hotelTotalRatings} Ratings)</p>
            </div>
          </div> */}
          <div className="price_row d-flex align-items-center flex-wrap justify-content-between mt-4">
            {/* <div className="price_detail">
            <h2>${hotel.price}</h2>
              <p>per room per night</p>
            </div> */}
            <button
              className="btn_green"
              style={{ width: "100%" }}
              onClick={this.gotoLink.bind(this, hotel.link)}
            >
              Book Now
            </button>
          </div>
        </div>
      </Col>
    );
  }
}

export default Header;
