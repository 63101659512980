import axios from 'axios';

import { 

    FETCH_SUPER8_BY_ID_REQUEST,
    FETCH_SUPER8_BY_ID_SUCCESS,
    FETCH_SUPER8_BY_ID_ERROR,

    FETCH_SUPER8_REQUEST,
    FETCH_SUPER8_SUCCESS,
    FETCH_SUPER8_ERROR,

    UPDATE_SUPER8_REQUEST,
    UPDATE_SUPER8_SUCCESS,
    UPDATE_SUPER8_ERROR,  

} from '../constants/Super8.const';

import { Auth_Key, API_Url } from '../helpers/authToken'
// var leagueId = '5e9ff764f24c8931527278bb';

export const fetchSuper8ById = (leagueId) => dispatch => {
    dispatch({type: FETCH_SUPER8_BY_ID_REQUEST})
    axios({
        method: 'POST',
        url: `${API_Url}league/event/list`,
        data: {
            leageId: leagueId,
            limit: 100,
            page: 0
        },
        headers: {
        'x-auth-token': Auth_Key,
        'Accept': 'application/json'}
        })

    .then(events => {
        localStorage.setItem('leagueId', leagueId)
        dispatch({
            type: FETCH_SUPER8_BY_ID_SUCCESS,
            payload: events.data.result
          })
    })
    .catch((error) => {
        dispatch({type: FETCH_SUPER8_BY_ID_ERROR, error: error})
    })
};

export const fetchSuper8 = (super8Id) => dispatch => {
    dispatch({type: FETCH_SUPER8_REQUEST})
    axios({
        method: 'get',
        url: `${API_Url}event?id=${super8Id}`,
        headers: {
        'x-auth-token': Auth_Key}
        })

    .then(super8 => {
        dispatch({
            type: FETCH_SUPER8_SUCCESS,
            payload: super8.data.result.data[0]
          })
    })
    .catch((error) => {
        dispatch({type: FETCH_SUPER8_ERROR, error: error})
    })
};

export const updateSuper8 = (super8) => dispatch => {
    dispatch({type: UPDATE_SUPER8_REQUEST})
    axios({
        method: 'POST',
        url: `${API_Url}league/event/select`,
        data: super8,
        headers: {
        'x-auth-token': Auth_Key,
        'Accept': 'application/json'}
        })

    .then(events => {

        if(events.data.flag === true)
        {
            dispatch({
                type: UPDATE_SUPER8_SUCCESS,
                payload: events.data.result
              })
        }
        else
        {
            dispatch({type: UPDATE_SUPER8_ERROR, error: events.data.message})
        }

    })
    .catch((error) => {
        dispatch({type: UPDATE_SUPER8_ERROR, error: error.response.data.message})
    })
};