import axios from "axios";

import {
  FETCH_ALL_LEAGUES_REQUEST,
  FETCH_ALL_LEAGUES_SUCCESS,
  FETCH_ALL_LEAGUES_ERROR,
  JOIN_REQUEST,
  JOIN_SUCCESS,
  JOIN_ERROR,
  FETCH_LEAGUE_BY_ID_REQUEST,
  FETCH_LEAGUE_BY_ID_SUCCESS,
  FETCH_LEAGUE_BY_ID_ERROR,
  FETCH_PAYMENT_INTENT_REQUEST,
  FETCH_PAYMENT_INTENT_SUCCESS,
  FETCH_PAYMENT_INTENT_ERROR,
  FETCH_VENMO_TOKEN_REQUEST,
  FETCH_VENMO_TOKEN_SUCCESS,
  FETCH_VENMO_TOKEN_ERROR,
  SEND_NONCE_REQUEST,
  SEND_NONCE_SUCCESS,
  SEND_NONCE_ERROR,
  PAYMENT_COMPLETE_REQUEST,
  PAYMENT_COMPLETE_SUCCESS,
  PAYMENT_COMPLETE_ERROR,
  EVENT_REGISTRATION_REQUEST,
  EVENT_REGISTRATION_SUCCESS,
  EVENT_REGISTRATION_ERROR,
} from "../constants/League.const";

import { Auth_Key, API_Url } from "../helpers/authToken";

export const fetchPaymentIntent = (data) => (dispatch) => {
  dispatch({ type: FETCH_PAYMENT_INTENT_REQUEST });
  axios({
    method: "POST",
    url: `${API_Url}payment/create-payment-intent`,
    data: data,
    headers: {
      "x-auth-token": Auth_Key,
      Accept: "application/json",
    },
  })
    .then((intent) => {
      if (intent.data.result === undefined) {
        dispatch({ type: FETCH_PAYMENT_INTENT_ERROR, error: intent.data });
      } else {
        dispatch({
          type: FETCH_PAYMENT_INTENT_SUCCESS,
          payload: intent.data.result.data,
        });
      }
    })
    .catch((error) => {
      dispatch({ type: FETCH_PAYMENT_INTENT_ERROR, error: error });
    });
};

export const fetchVenmoToken = (data) => (dispatch) => {
  dispatch({ type: FETCH_VENMO_TOKEN_REQUEST });
  axios({
    method: "POST",
    url: `${API_Url}payment/venmo/token`,
    data: data,
    headers: {
      "x-auth-token": Auth_Key,
      Accept: "application/json",
    },
  })
    .then((venmo) => {
      dispatch({
        type: FETCH_VENMO_TOKEN_SUCCESS,
        payload: venmo.data.result,
      });
    })
    .catch((error) => {
      dispatch({ type: FETCH_VENMO_TOKEN_ERROR, error: error });
    });
};

export const sendNonceToServer = (data) => (dispatch) => {
  dispatch({ type: SEND_NONCE_REQUEST });
  axios({
    method: "POST",
    url: `${API_Url}payment/venmo/checkout`,
    data: data,
    headers: {
      "x-auth-token": Auth_Key,
      Accept: "application/json",
    },
  })
    .then((venmo) => {
      dispatch({
        type: SEND_NONCE_SUCCESS,
        payload: venmo.data.result,
      });
    })
    .catch((error) => {
      dispatch({ type: SEND_NONCE_ERROR, error: error });
    });
};

export const fetchLeagueById = () => (dispatch) => {
  dispatch({ type: FETCH_LEAGUE_BY_ID_REQUEST });
  axios({
    method: "GET",
    url: `${API_Url}league/web/live`,
    headers: {
      "x-auth-token": Auth_Key,
      Accept: "application/json",
    },
  })
    .then((topLeagues) => {
      localStorage.setItem("leagueId", topLeagues.data.result.data[0]._id);
      dispatch({
        type: FETCH_LEAGUE_BY_ID_SUCCESS,
        payload: topLeagues.data.result.data[0],
      });
    })
    .catch((error) => {
      dispatch({ type: FETCH_LEAGUE_BY_ID_ERROR, error: error });
    });
};

export const fetchAllLeagues = () => (dispatch) => {
  dispatch({ type: FETCH_ALL_LEAGUES_REQUEST });
  axios({
    method: "get",
    url: `${API_Url}league`,
    headers: {
      "x-auth-token": Auth_Key,
    },
  })
    .then((topLeagues) => {
      dispatch({
        type: FETCH_ALL_LEAGUES_SUCCESS,
        payload: topLeagues.data.result.data,
      });
    })
    .catch((error) => {
      dispatch({ type: FETCH_ALL_LEAGUES_ERROR, error: error });
    });
};

export const joinLeague = (roster) => (dispatch) => {
  dispatch({ type: JOIN_REQUEST });

  console.log("roster:---", roster);
 
  axios({
    method: "POST",
    url: `${API_Url}event/registration`,
    data: roster,
    headers: {
      "x-auth-token": Auth_Key,
      Accept: "application/json",
    },
  })
    .then((league) => {
      console.log("joinLeague:---", league);
      if (league.data.result === undefined)
        dispatch({ type: JOIN_ERROR, error: league.data });
      else dispatch({ type: JOIN_SUCCESS, payload: league.data.result });
    })
    .catch((error) => {
      dispatch({ type: JOIN_ERROR, error: error });
    });
};

export const paymentComplete = (data) => (dispatch) => {
  console.log("payment request data:---", data);
  dispatch({ type: PAYMENT_COMPLETE_REQUEST });
  axios({
    method: "POST",
    url: `${API_Url}event/payment/complete`,
    data: data,
    headers: {
      "x-auth-token": Auth_Key,
      Accept: "application/json",
    },
  })
    .then((league) => {
      console.log("league:---", league);
      if (league.data.result === undefined)
        dispatch({ type: PAYMENT_COMPLETE_ERROR, error: league.data });
      else
        dispatch({
          type: PAYMENT_COMPLETE_SUCCESS,
          payload: league.data.result,
        });
    })
    .catch((error) => {
      dispatch({ type: PAYMENT_COMPLETE_ERROR, error: error });
    });
};

export const leagueComplete = (data) => (dispatch) => {
  dispatch({ type: EVENT_REGISTRATION_REQUEST });
  axios({
    method: "POST",
    url: `${API_Url}event/registration`,
    data: data,
    headers: {
      "x-auth-token": Auth_Key,
      Accept: "application/json",
    },
  })
    .then((league) => {
      console.log("leagueComplete:---", league);
      if (league.data.result === undefined)
        dispatch({ type: EVENT_REGISTRATION_ERROR, error: league.data });
      else
        dispatch({
          type: EVENT_REGISTRATION_SUCCESS,
          payload: league.data.result,
        });
    })
    .catch((error) => {
      dispatch({ type: EVENT_REGISTRATION_ERROR, error: error });
    });
};
