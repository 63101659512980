export const FETCH_TOP_EVENTS_REQUEST = 'FETCH_TOP_EVENTS_REQUEST';
export const FETCH_TOP_EVENTS_SUCCESS = 'FETCH_TOP_EVENTS_SUCCESS';
export const FETCH_TOP_EVENTS_ERROR   = 'FETCH_TOP_EVENTS_ERROR';

export const FETCH_ALL_EVENTS_REQUEST = 'FETCH_ALL_EVENTS_REQUEST';
export const FETCH_ALL_EVENTS_SUCCESS = 'FETCH_ALL_EVENTS_SUCCESS';
export const FETCH_ALL_EVENTS_ERROR   = 'FETCH_ALL_EVENTS_ERROR';

export const FETCH_LOCAL_EVENTS_REQUEST = 'FETCH_LOCAL_EVENTS_REQUEST';
export const FETCH_LOCAL_EVENTS_SUCCESS = 'FETCH_LOCAL_EVENTS_SUCCESS';
export const FETCH_LOCAL_EVENTS_ERROR   = 'FETCH_LOCAL_EVENTS_ERROR';

export const FETCH_EVENT_BY_ID_REQUEST = 'FETCH_EVENT_BY_ID_REQUEST';
export const FETCH_EVENT_BY_ID_SUCCESS = 'FETCH_EVENT_BY_ID_SUCCESS';
export const FETCH_EVENT_BY_ID_ERROR   = 'FETCH_EVENT_BY_ID_ERROR';