export const FETCH_SUPER8_BY_ID_REQUEST = 'FETCH_SUPER8_BY_ID_REQUEST';
export const FETCH_SUPER8_BY_ID_SUCCESS = 'FETCH_SUPER8_BY_ID_SUCCESS';
export const FETCH_SUPER8_BY_ID_ERROR   = 'FETCH_SUPER8_BY_ID_ERROR';

export const FETCH_SUPER8_REQUEST = 'FETCH_SUPER8_REQUEST';
export const FETCH_SUPER8_SUCCESS = 'FETCH_SUPER8_SUCCESS';
export const FETCH_SUPER8_ERROR   = 'FETCH_SUPER8_ERROR';

export const UPDATE_SUPER8_REQUEST = 'UPDATE_SUPER8_REQUEST';
export const UPDATE_SUPER8_SUCCESS = 'UPDATE_SUPER8_SUCCESS';
export const UPDATE_SUPER8_ERROR   = 'UPDATE_SUPER8_ERROR';