import React from 'react';
import {
  NavLink,
} from 'reactstrap';


class LocationBox extends React.Component {

  toLocation = () => {
    var url = "https://maps.google.com/?q=" + this.props.location.latitude + "," + this.props.location.longitude;
    window.open(url);
  }

  render() {
    const location = this.props.location;
    const src = location ? `https://www.google.com/maps/embed/v1/view?key=AIzaSyBIWNIuBhh-9yA3DdSZGUZ-revs4vH_Www&center=${location.latitude},${location.longitude}&zoom=10` : ""
    
    return (
      <div className="web_description_box">
        <div className="web_desc_two_title d-flex align-items-center flex-wrap justify-content-between">
          <h3>Location</h3>
          <NavLink href="#" onClick={this.toLocation} className=" d-flex align-items-center common_export_icon">View on Google Map</NavLink>
        </div>
        <p>{location && location.streetAddress}</p>
        {/* latitude: 21.1702401
        longitude: 72.83106070000001 */}
        <div className="map_wrapper rounded-lg overflow-hidden">
          <iframe title="videoFrame" src={src} width="100%" height="232" frameBorder="0" ></iframe>
        </div>
      </div >
    );
  }
}

export default LocationBox;