import React from 'react';
import { Card } from 'reactstrap';
import moment from 'moment'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchTeams } from '../../saga/actions/Teams.action';
import Login from "./Login"
import {checkLoginValidation} from '../../saga/helpers/authToken';


class EventBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      event : ''
    }
    this.eventDetail = this.eventDetail.bind(this);
  }

  eventDetail = (event) => {

    var isloggedIn = checkLoginValidation();

    if(isloggedIn)
    {
      localStorage.setItem('eventId', event._id)
      this.props.fetchTeams('5e8190114fdcbe32975d3c26')
      this.props.history.push({ pathname: '/WebApp/detail'})
    }
    else
    {
      this.props.isShowLogin();
    }
  }

  popupClose = () => {
    this.setState({
      isShowLogin: false
    })
  }

  render() {

    const event = this.props.eventData

    return (
        <Card className="event_card mb_25" onClick={this.eventDetail.bind(this, event)} style={{cursor: 'pointer'}} >
          <div className="event_card_img">
            <img src={event.eventImages && event.eventImages.filePath} alt="event" />
            <span className="event_card_address">{ event.location && event.location.city }</span>
          </div>
         
          <div className="event_card_desc">
             {
              event.isFeatured === true ?  
              <div className="event_tags text-right">
                <span className="">Top Event</span>
              </div> : ''
            }
            <div className="event_card_logo">
              <img src={ event.eventLogo && event.eventLogo.filePath ? event.eventLogo && event.eventLogo.filePath :"/web_images/Default_Banner.png"} alt="event logo" />
            </div>
            <div className="event_card_titles">
              <h3>{event.eventTitle}</h3>
              <div className="event_card_dates">
                <p className="date_icon">{moment(event.startDate).format('DD-MMM')} - {moment(event.endDate).format('DD-MMM')}</p>
                  <p className="team_icons">{event.teamLimit} {event.eventTeamType === 1 ? 'Teams' : 'Players'}</p>
              </div>
            </div>
          </div>
          <div>
          {
            this.state.isShowLogin ? <Login history={this.props.history} class='custom_popup open auth_form m-0' onClose={this.popupClose} /> : ''
          }
        </div>
        </Card>
    );
  }
}

EventBox.propTypes = {
  fetchTeams: PropTypes.func.isRequired,
};

export default connect(null, { fetchTeams })(EventBox);