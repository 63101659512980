import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutForm from './CheckoutForm';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

class StripePayment extends React.Component {

  onPaymentSuccess = (payment) => {
    this.props.onPaymentSuccess(payment)
  }

  onPaymentError = (error) => {
    this.props.onPaymentError(error)
  }

  render() {
    return (
      <Elements stripe={stripePromise}>
        <CheckoutForm paymentSuccess={this.onPaymentSuccess} paymentError={this.onPaymentError} props={this.props} />
      </Elements>
    );
  }
}

export default StripePayment;