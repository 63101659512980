import React, { Component } from 'react';
import Header from "../Header";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// import HotelNearVenue from "../HotelNearVenue";
import { fetchLeagueById } from '../../../saga/actions/League.action';
import LeagueListRow from "../League/LeagueListRow";

class LeagueJoined extends Component {

  constructor(props) {
    super(props);
    this.state = {
      transactioId:"2432655675e464324",
      amount:0,
      paymentMethod:1,
      card:"**** **** **** 7567"
    }
  }

  componentWillMount() {
    this.props.fetchLeagueById();
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.leagueData)
    {
      if(nextProps.leagueData.league && nextProps.leagueData.isLeagueLoaded)
      {
        nextProps.leagueData.isLeagueLoaded = false
        var leagueData= nextProps.leagueData.league;  
  
        this.setState({
          league: leagueData
        })
      }
      if(nextProps.leagueData && nextProps.leagueData.paymentComplete &&  nextProps.leagueData.paymentComplete.metaPayment){
        this.setState({
          transactioId:nextProps.leagueData.paymentComplete._id,
          amount:nextProps.leagueData.paymentComplete.amount,
          paymentMethod:nextProps.leagueData.paymentComplete.paymentMethod,
          card: "**** **** **** " + nextProps.leagueData.paymentComplete.metaPayment.payment_method_details.card.last4
        })
    
        nextProps.leagueData.paymentComplete=""
       }
    }
  }

  render() {
    const league = this.state.league;
    var paymentMath = "Full Payment $"+this.state.amount;

    if(this.state.paymentMethod===1){
      paymentMath= "Full Payment $"+this.state.amount
    }
    else if(this.state.paymentMethod===2){
      paymentMath= "Day Off $"+this.state.amount
    }
    else if(this.state.paymentMethod===3){
      paymentMath= "Pay First Installment $"+this.state.amount
    }
    return (
      <div className="web_wrapper">
        <div className="web_main inner_pages">
          <div className="web_banner">
            <img src="/web_images/banner.png" alt="banner" />
          </div>
          <div className="web_container">
          <Header history={this.props.history}/>
          </div>
        </div>
        <div className="web_container_1290">
          <div className="web_eventOuterList">
            {
              league ? <LeagueListRow history={this.props.history} isHideJoinButton={true} leagueData={league} isGotoPayment={true} isJoined={true} /> : ''
            }
          </div>
        </div>
        <div className="container_900">
          <div className="bg_white web_description_box">
            <div className="web_join_title text-center">
              <h1 className="green_text">Thank You!</h1>
              <p>Your payment is successful and your team is joined league</p>
            </div>
            <div className="transaction_detail">
              <div className="transaction_row d-flex flex-wrap align-items-center">
                <label>Transaction ID:</label>
                <p>{this.state.transactioId}</p>
              </div>
              <div className="transaction_row d-flex flex-wrap align-items-center">
                <label>Payment Method:</label>
                <p>{paymentMath}</p>
              </div>
              <div className="transaction_row d-flex flex-wrap align-items-center">
                <label>Payment Type:</label>
                <p>Credit Card</p>
              </div>
              <div className="transaction_row d-flex flex-wrap align-items-center">
                <label>Card Number:</label>
                <p>{this.state.card}</p>
              </div>
            </div>
            {/* <div className="web_hotel_venue_main">
              <div className="text-center">
                <h1>Hotels Near Venue</h1>
              </div>
              <HotelNearVenue />
              <HotelNearVenue />
              <HotelNearVenue />
              <HotelNearVenue />
            </div> */}
          </div>
        </div>
      </div >
    );
  }
}


LeagueJoined.propTypes = {
  fetchLeagueById: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  leagueData: state.league,
});

export default connect(mapStateToProps, { fetchLeagueById })(LeagueJoined);