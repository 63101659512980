import React from "react";
import { Col } from 'reactstrap';
import moment from "moment";
import {getUserRole} from '../../../saga/helpers/authToken';

class EventCheck extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userRole: getUserRole(),
    };
    this.eventSelect = this.eventSelect.bind(this);
    this.super8Detail = this.super8Detail.bind(this);
  }

  popupClose = () => {
    this.setState({
      isShowLogin: false,
    });
  };

  eventSelect = (super8Id, e) => {
    this.props.eventSelect(super8Id, e)
  }

  super8Detail = (super8Id) => {
    localStorage.setItem('super8Id', super8Id)
    this.props.history.push({ pathname: '/WebApp/super8'})
  }

  render() {
     const super8 = this.props.super8Data;
     const isShowCheck = this.props.isShowCheck;
    return (
      <>
        <Col xl={3} md={4} sm={6} xs={12}>
            <div className="event_card mb_25 card" onClick={this.super8Detail.bind(this, super8._id)} style={{cursor: 'pointer'}}>
              <div className="event_card_img">
                <img
                  alt="Banner"
                  src={super8.eventImages && super8.eventImages.filePath}
                />
                <span className="event_card_address">{ super8.location && super8.location.city }</span>
              </div>
             
              <div className="event_card_desc">
                <div className="event_tags text-right">
                  <span className="">Super 8</span>
                </div>
                <div className="event_card_logo">
                  <img
                    alt="event logo"
                    src={ super8.eventLogo && super8.eventLogo.filePath ? super8.eventLogo && super8.eventLogo.filePath :"/web_images/Default_Banner.png"}
                  />
                </div>
                <div className="event_card_titles">
                  <h3>{super8.eventTitle}</h3>
                  <div className="event_card_dates">
                  <p className="date_icon">{moment(super8.startDate).format('DD-MMM')} - {moment(super8.endDate).format('DD-MMM')}</p>
                    <p className="team_icons">{super8.teamLimit} Teams</p>
                  </div>
                </div>
              </div>
            </div>

            {

              this.state.userRole === 'Player' || this.state.userRole === 'Fan' || isShowCheck === false ? '' : 

              <div className="check_event">
                <input type="checkbox" id={super8._id}  checked={super8.selPer === 1 ? true : false}/>
                <label className="check" for={super8._id}  onClick={this.eventSelect.bind(this, super8._id)}>
                  {" "}
                </label>
              </div>

            }

        </Col>
      </>
    );
  }
}

export default EventCheck;
