import { 
  FETCH_TOP_EVENTS_REQUEST, 
  FETCH_TOP_EVENTS_SUCCESS, 
  FETCH_TOP_EVENTS_ERROR,

  FETCH_ALL_EVENTS_REQUEST, 
  FETCH_ALL_EVENTS_SUCCESS, 
  FETCH_ALL_EVENTS_ERROR,

  FETCH_LOCAL_EVENTS_REQUEST, 
  FETCH_LOCAL_EVENTS_SUCCESS, 
  FETCH_LOCAL_EVENTS_ERROR,

  FETCH_EVENT_BY_ID_REQUEST, 
  FETCH_EVENT_BY_ID_SUCCESS, 
  FETCH_EVENT_BY_ID_ERROR,

} from '../constants/Events.const';

const initialState = {
  topEvents: [],
  topEventsError: null,
  topEventsLoader: false,
  isTopEventsLoaded : false,

  allEvents: [],
  allEventsError: null,
  allEventsLoader: false,
  isAllEventsLoaded : false,

  localEvents: [],
  localEventsError: null,
  localEventsLoader: false,
  isLocalEventsLoaded : false,

  event: [],
  eventError: null,
  eventLoader: false,
  isEventLoaded : false,

};

export default function(state = initialState, action) {
  switch (action.type) {

    // For GET Event Data

    case FETCH_TOP_EVENTS_REQUEST:
      return {
        ...state,
        topEventsLoader: true
      };
    case FETCH_TOP_EVENTS_SUCCESS:
      return {
        ...state,
        isTopEventsLoaded : true,
        topEvents: action.payload,
        topEventsLoader: false
      };
    case FETCH_TOP_EVENTS_ERROR:
      return {
        ...state,
        topEventsError: action.error.message,
        topEventsLoader: false
      };

    // For GET ALL Event Data

    case FETCH_ALL_EVENTS_REQUEST:
      return {
        ...state,
        allEventsLoader: true
      };
    case FETCH_ALL_EVENTS_SUCCESS:
      return {
        ...state,
        isAllEventsLoaded : true,
        allEvents: action.payload,
        allEventsLoader: false
      };
    case FETCH_ALL_EVENTS_ERROR:
      return {
        ...state,
        allEventsError: action.error.message,
        allEventsLoader: false
      };

    // For GET LOCAL Event Data

    case FETCH_LOCAL_EVENTS_REQUEST:
      return {
        ...state,
        localEventsLoader: true
      };
    case FETCH_LOCAL_EVENTS_SUCCESS:
      return {
        ...state,
        isLocalEventsLoaded : true,
        localEvents: action.payload,
        localEventsLoader: false
      };
    case FETCH_LOCAL_EVENTS_ERROR:
      return {
        ...state,
        localEventsError: action.error.message,
        localEventsLoader: false
      };

    // For GET Event By Id Data

    case FETCH_EVENT_BY_ID_REQUEST:
      return {
        ...state,
        eventLoader: true
      };
    case FETCH_EVENT_BY_ID_SUCCESS:
      return {
        ...state,
        isEventLoaded : true,
        event: action.payload,
        eventLoader: false
      };
    case FETCH_EVENT_BY_ID_ERROR:
      return {
        ...state,
        eventError: action.error.message,
        eventLoader: false
      };


    default:
      return state;
  }
}