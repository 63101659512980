import React from "react";
import moment from "moment";
import Login from "../Login";
import { checkLoginValidation } from "../../../saga/helpers/authToken";

class LeagueListRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.payment = this.payment.bind(this);
  }

  payment = () => {
    var isloggedIn = checkLoginValidation();

    if (isloggedIn) {
      if (this.props.isGotoPayment)
        this.props.history.push("/WebApp/league/join");
      else this.props.history.push("/WebApp/league");
    } else {
      this.setState({
        isShowLogin: true,
      });
    }
  };

  popupClose = () => {
    this.setState({
      isShowLogin: false,
    });
  };

  render() {
    const league = this.props.leagueData;
   
    return (
      <>
        {league.length === 0 ? (
          <div className="comeSoon">
            <h1>Comming Soon !</h1>
          </div>
        ) : (
          <div className="web_eventlist_wrapper d-flex flex-wrap align-items-center">
            <div className="web_eventList_img event_card_img">
              <img
                src={
                  league.leagueImages && league.leagueImages.filePath
                    ? league.leagueImages && league.leagueImages.filePath
                    : "/web_images/Default_Banner.png"
                }
                alt="event banner"
              />
              <span className="event_card_address">
                {league.location && league.location.city}
              </span>
            </div>
            <div className="web_eventList_content d-flex flex-wrap align-items-center">
              <div className="web_eventList_data d-flex flex-wrap align-items-center">
                <div className="web_eventList_logo">
                  <img
                    src={
                      league.leagueLogo && league.leagueLogo.filePath
                        ? league.leagueLogo && league.leagueLogo.filePath
                        : "/web_images/Default_Banner.png"
                    }
                    alt="event logo"
                  />
                </div>
                <div className="web_eventList_detail">
                  <h1>{league.leagueTitle}</h1>
                  <div className="web_eventList_icons d-flex flex-wrap">
                    <span className="common_softball_icon d-flex align-items-center">
                      {league.eventType === 1 ? "Baseball" : "Softball"}
                    </span>
                    <span className="common_date_icon d-flex align-items-center">
                      {moment(league.startDate).format("DD-MMM")} -{" "}
                      {moment(league.endDate).format("DD-MMM")}
                    </span>
                    <span className="common_team_icon d-flex align-items-center">
                      {league.teamLimit} Teams
                    </span>
                  </div>
                </div>
              </div>
              {this.props.isJoined === true ? (
                <div>
                  <button
                    className="btn_green"
                    style={{ cursor: "not-allowed" }}
                  >
                    Joined
                  </button>
                </div>
              ) : (
                <div className="web_eventList_join">
                  <h2>
                    ${league.payment && league.payment.feesLeague}{" "}
                    <span>Fee to Join</span>
                  </h2>
                  {this.props.isHideJoinButton ? (
                    ""
                  ) : (
                    <button className="btn_green" onClick={this.payment}>
                      Join
                    </button>
                  )}
                </div>
              )}
            </div>
            <div>
              {this.state.isShowLogin ? (
                <Login
                  history={this.props.history}
                  class="custom_popup open auth_form m-0"
                  onClose={this.popupClose}
                />
              ) : (
                ""
              )}
            </div>
          </div>
        )}
      </>
    );
  }
}

export default LeagueListRow;
