import axios from 'axios';
import { Auth_Key, API_Url } from '../helpers/authToken'
import { 

    FETCH_TEAMS_REQUEST,
    FETCH_TEAMS_SUCCESS,
    FETCH_TEAMS_ERROR,

} from '../constants/Teams.const';


export const fetchTeams = (eventId) => dispatch => {
    dispatch({type: FETCH_TEAMS_REQUEST})
    // checkIsValidToken(histrory)
    axios({
        method: 'GET',
        url: `${API_Url}event/participateTeam?id=${eventId}`,
        headers: {
        'x-auth-token': Auth_Key, 
        'Accept': 'application/json'}
        })

    .then(teams => {
        dispatch({
            type: FETCH_TEAMS_SUCCESS,
            payload: teams.data.result.data
        })
    })
    .catch((error) => {
        // if(error.response && error.response.status === 401)
        // Authorization(histrory)
        dispatch({type: FETCH_TEAMS_ERROR, error: error})
    });
};