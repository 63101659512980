import React from 'react';


class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      setIsOpen: false
    }
  }

  toggle = () => {
    this.setState({
      setIsOpen: !this.state.setIsOpen
    })
  }

  render() {
    return (
      <div className="web_footer bg_white">
       	<div className="web_container text-center">
       		<p>&copy; 2020 Diamond Connect. All rights reserved.</p>
       	</div>
      </div>
    );
  }
}

export default Footer;