import axios from 'axios';

import { 
    FETCH_TOP_EVENTS_REQUEST, 
    FETCH_TOP_EVENTS_SUCCESS, 
    FETCH_TOP_EVENTS_ERROR,

    FETCH_ALL_EVENTS_REQUEST, 
    FETCH_ALL_EVENTS_SUCCESS, 
    FETCH_ALL_EVENTS_ERROR,

    FETCH_LOCAL_EVENTS_REQUEST, 
    FETCH_LOCAL_EVENTS_SUCCESS, 
    FETCH_LOCAL_EVENTS_ERROR,


    FETCH_EVENT_BY_ID_REQUEST, 
    FETCH_EVENT_BY_ID_SUCCESS, 
    FETCH_EVENT_BY_ID_ERROR,

} from '../constants/Events.const';

import { Auth_Key, API_Url } from '../helpers/authToken'


export const fetchTopEvents = (event) => dispatch => {
   
    dispatch({type: FETCH_TOP_EVENTS_REQUEST})
    if(Auth_Key){
        axios({
            method: 'get',
            url: `${API_Url}event/filter?eventType=${event.type}&isPresent=${event.isPresent}`,
            headers: {
                'x-auth-token': Auth_Key}
                })
    
        .then(topEvents => {
            dispatch({
                type: FETCH_TOP_EVENTS_SUCCESS,
                payload: topEvents.data.result.data
              })
        })
        .catch((error) => {
            dispatch({type: FETCH_TOP_EVENTS_ERROR, error: error})
        })
    }
    else{
        axios({
            method: 'get',
            url: `${API_Url}event/web/list?eventType=${event.type}&isPresent=${event.isPresent}`,
            
                })
    
        .then(topEvents => {
            dispatch({
                type: FETCH_TOP_EVENTS_SUCCESS,
                payload: topEvents.data.result.data
              })
        })
        .catch((error) => {
            dispatch({type: FETCH_TOP_EVENTS_ERROR, error: error})
        })
    }
  
};

export const fetchAllEvents = (event) => dispatch => {
    dispatch({type: FETCH_ALL_EVENTS_REQUEST})
    if(Auth_Key){
        axios({
            method: 'get',
                url: `${API_Url}event/filter?eventType=${event.type}&isPresent=${event.isPresent}`,
            headers: {
            'x-auth-token': Auth_Key}
            })
    
        .then(topEvents => {
            dispatch({
                type: FETCH_ALL_EVENTS_SUCCESS,
                payload: topEvents.data.result.data
              })
        })
        .catch((error) => {
            dispatch({type: FETCH_ALL_EVENTS_ERROR, error: error})
        })
    }else{
        axios({
            method: 'get',
                url: `${API_Url}event/web/list?eventType=${event.type}&isPresent=${event.isPresent}`,
            })
    
        .then(topEvents => {
            dispatch({
                type: FETCH_ALL_EVENTS_SUCCESS,
                payload: topEvents.data.result.data
              })
        })
        .catch((error) => {
            dispatch({type: FETCH_ALL_EVENTS_ERROR, error: error})
        })
    }
   
};

export const fetchLocalEvents = (event) => dispatch => {
    dispatch({type: FETCH_LOCAL_EVENTS_REQUEST})
    if(Auth_Key){
        axios({
            method: 'get',
            url: `${API_Url}event/web/list?eventType=${event.type}&isPresent=${event.isPresent}&lat=${event.lat}&long=${event.long}`,
            headers: {
            'x-auth-token': Auth_Key}
            })
    
        .then(topEvents => {
            dispatch({
                type: FETCH_LOCAL_EVENTS_SUCCESS,
                payload: topEvents.data.result.data
              })
        })
        .catch((error) => {
            dispatch({type: FETCH_LOCAL_EVENTS_ERROR, error: error})
        })
    }else{
        axios({
            method: 'get',
            url: `${API_Url}event/web/list?eventType=${event.type}&isPresent=${event.isPresent}&lat=${event.lat}&long=${event.long}`,
            })
    
        .then(topEvents => {
            dispatch({
                type: FETCH_LOCAL_EVENTS_SUCCESS,
                payload: topEvents.data.result.data
              })
        })
        .catch((error) => {
            dispatch({type: FETCH_LOCAL_EVENTS_ERROR, error: error})
        })
    }
   
};

export const fetchEventById = (eventId) => dispatch => {
    dispatch({type: FETCH_EVENT_BY_ID_REQUEST})
    axios({
        method: 'get',
            url: `${API_Url}event?id=${eventId}`,
        headers: {
        'x-auth-token': Auth_Key,
        'Accept': 'application/json'}
        })

    .then(topEvents => {
        localStorage.setItem('eventId', eventId)
        dispatch({
            type: FETCH_EVENT_BY_ID_SUCCESS,
            payload: topEvents.data.result.data[0]
          })
    })
    .catch((error) => {
        dispatch({type: FETCH_EVENT_BY_ID_ERROR, error: error})
    })
};