import { 

    FETCH_TEAMS_REQUEST,
    FETCH_TEAMS_SUCCESS,
    FETCH_TEAMS_ERROR,

} from '../constants/Teams.const';

const initialState = {

  allTeams: [],
  allTeamsError: null,
  allTeamsLoader: false,
  isTeamsLoaded: false

};

export default function(state = initialState, action) {
  switch (action.type) {

    // Fetch - ALL TEAMS

    case FETCH_TEAMS_REQUEST:
    return {
        ...state,
        allTeamsLoader: true
    };
    case FETCH_TEAMS_SUCCESS:
    return {
        ...state,
        isTeamsLoaded: true,
        allTeams: action.payload,
        allTeamsLoader: false
    };
    case FETCH_TEAMS_ERROR:
    return {
        ...state,
        allTeamsError: action.error.message,
        allTeamsLoader: false
    };

    default:
      return state;
  }
}