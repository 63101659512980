import React from 'react';

class SponsorBox extends React.Component {

  render() {
    const sponsor = this.props.sponsor;

    return (
      <div className="web_description_fullborderbox">
        <div className="web_desc_title">
          <h3>Sponsor</h3>
        </div>
        <div className="web_description_box_20">
          <div className="companyLogos">
            <img src={sponsor.logo && sponsor.logo.filePath} alt="sponsor" className="rounded" />
          </div>
          <div className="web_hotel_address">
            <h2>{ sponsor.name }</h2>
          </div> 
        </div>
      </div>
    );
  }
}

export default SponsorBox;