import { 
  FETCH_TOP_EVENTS_REQUEST, 
  FETCH_TOP_EVENTS_SUCCESS, 
  FETCH_TOP_EVENTS_ERROR,

  FETCH_APP_SIGNUP_GRAPH_REQUEST,
  FETCH_APP_SIGNUP_GRAPH_SUCCESS,
  FETCH_APP_SIGNUP_GRAPH_ERROR  
} from '../constants/Dashboard.const';

const initialState = {
  topEvents: [],
  topEventsError: null,
  topEventsLoader: false,
  isEventsLoaded: false,

  appSignupGraphData: [],
  appSignupGraphDataError: null,
  appSignupGraphDataLoader: false,
  isAppSignupGraphDataLoaded: false

};

export default function(state = initialState, action) {
  switch (action.type) {

    // For fetch TOP EVENTS
    case FETCH_TOP_EVENTS_REQUEST:
      return {
        ...state,
        topEventsLoader: true
      };
    case FETCH_TOP_EVENTS_SUCCESS:
      return {
        ...state,
        isEventsLoaded: true,
        topEvents: action.payload,
        topEventsLoader: false
      };
    case FETCH_TOP_EVENTS_ERROR:
      return {
        ...state,
        topEventsError: action.error.message,
        topEventsLoader: false
      };

      // For fetch APP SIGNUP GRAPHS

      case FETCH_APP_SIGNUP_GRAPH_REQUEST:
        return {
          ...state,
          appSignupGraphDataLoader: true
        };
      case FETCH_APP_SIGNUP_GRAPH_SUCCESS:
        return {
          ...state,
          isAppSignupGraphDataLoaded: true,
          appSignupGraphData: action.payload,
          appSignupGraphDataLoader: false
        };
      case FETCH_APP_SIGNUP_GRAPH_ERROR:
        return {
          ...state,
          appSignupGraphDataError: action.error.message,
          appSignupGraphDataLoader: false
        };
    default:
      return state;
  }
}